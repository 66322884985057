import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper"; // Import Autoplay
import axios from 'axios';
import 'swiper/swiper-bundle.css'; // Ensure styles are imported

const BannerSlide = ({ onBannersAvailable }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery("(min-width: 600px) and (min-height: 858px)");
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await axios.get('https://backend.thecameratales.com/api/banners');
        setBanners(response.data);
        onBannersAvailable(response.data.length > 0); // Notify if banners are available
      } catch (error) {
        console.error("Error fetching banners:", error);
      }
    };

    fetchBanners();
  }, [onBannersAvailable]);

  // Render nothing if there are no banners
  if (banners.length === 0) {
    return null;
  }

  return (
    <Box sx={{ position: "relative", color: "primary.contrastText", marginTop: "7vh", paddingTop:"10px", backgroundColor:"black" }}>
      <Swiper
        grabCursor={true}
        loop={true}
        autoplay={{
          delay: 10000, // 10 seconds
          disableOnInteraction: false, // Continue autoplay after user interactions
        }}
        modules={[Autoplay]} // Register the Autoplay module
        style={{ width: "100%", height: "max-content" }}
      >
        {banners.map((banner, index) => {
          // Check if imageUrl or secondaryImageUrl exist and replace backslashes if necessary
          const imageUrl = banner.imageUrl ? banner.imageUrl.replace(/\\/g, '/') : '';
          const secondaryImageUrl = banner.secondaryImageUrl ? banner.secondaryImageUrl.replace(/\\/g, '/') : '';
          const bannerUrl = isLargeScreen ? secondaryImageUrl : imageUrl;

          return (
            <SwiperSlide key={index}>
              <a href={banner.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                <Box sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      paddingTop: { xs: "50%", sm: "30%", md: "20%" },
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundImage: `url(https://backend.thecameratales.com/${bannerUrl})`,
                      zIndex: 1,
                    }}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 2,
                      backgroundColor: "rgba(0, 0, 0, 0.7)", // Increased opacity
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        color: "white",
                        fontSize: { xs: "30px", sm: "50px" }, // 30px for screens below 600px width, 50px otherwise
                        fontWeight: "bold",
                        textAlign: "center",
                        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)"
                      }}
                    >
                      {banner.title}
                    </Typography>
                  </Box>
                </Box>
              </a>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
};

export default BannerSlide;
