import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Tooltip, Legend, ArcElement } from 'chart.js';
import { Box, Grid, Typography, Card, CardMedia, CardContent, CardActions, Button, Divider } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

// Register the components
Chart.register(CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend);

function AnalyticsPage() {
    const { userId } = useParams();
    const [movies, setMovies] = useState([]);
    const [progressData, setProgressData] = useState({});
    const [selectedMovieTitle, setSelectedMovieTitle] = useState('');
    const [geographyData, setGeographyData] = useState({
        labels: [],
        datasets: [{
            label: 'Audience Geography',
            data: [],
            backgroundColor: ['#212121', '#424242', '#616161', '#757575'],
        }],
    });
    const [geographyDataViews, setGeographyDataViews] = useState({
        labels: [],
        datasets: [{
            label: 'Views by State',
            data: [],
            backgroundColor: ['#212121', '#424242', '#616161', '#757575', '#BDBDBD'],
        }],
    });
    const [dataAvailable, setDataAvailable] = useState(false);
    const [likesByStateData, setLikesByStateData] = useState({
        labels: [],
        datasets: [{
            label: 'Likes by State (%)',
            data: [],
            backgroundColor: 'rgba(192, 192, 192, 0.8)',
        }],
    });
    const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
    const token = localStorage.getItem('token');
    axios.defaults.headers.common['x-auth-token'] = token;
    axios.defaults.baseURL = `${apiBaseURL}/api`;

    useEffect(() => {
        const fetchUserMovies = async () => {
            try {
                const response = await axios.get(`${apiBaseURL}/api/movie/user/${userId}/movies`, {
                    headers: { 'x-auth-token': token },
                    params: { timestamp: Date.now() },
                });
                setMovies(response.data);

                const progressPromises = response.data.map(movie =>
                    axios.get(`${apiBaseURL}/api/movie/progresses/${movie._id}`)
                );

                const progressResponses = await Promise.all(progressPromises);

                const progressByMediaId = progressResponses.reduce((acc, progressResponse, index) => {
                    acc[response.data[index]._id] = progressResponse.data.progress;
                    return acc;
                }, {});

                setProgressData(progressByMediaId);

                const viewsByState = {};
                let selectedMovie = null;

                for (const movieId in progressByMediaId) {
                    progressByMediaId[movieId].forEach(entry => {
                        const state = entry.userId.contactInfo?.address?.state || 'Unknown';
                        if (!viewsByState[state]) {
                            viewsByState[state] = 0;
                        }
                        viewsByState[state] += 1;
                    });
                    selectedMovie = response.data.find(movie => movie._id === movieId);
                }

                if (Object.keys(viewsByState).length > 0 && selectedMovie) {
                    setGeographyDataViews({
                        labels: Object.keys(viewsByState),
                        datasets: [{
                            label: `Views by State for "${selectedMovie.title}"`,
                            data: Object.values(viewsByState),
                            backgroundColor: ['#212121', '#424242', '#616161', '#757575', '#BDBDBD'],
                        }],
                    });
                    setSelectedMovieTitle(selectedMovie.title);
                    setDataAvailable(true);
                } else {
                    setDataAvailable(false);
                }

                const likesByState = {};

                response.data.forEach(movie => {
                    movie.likedBy.forEach(user => {
                        const state = user.contactInfo?.address?.state;
                        if (state) {
                            if (!likesByState[state]) {
                                likesByState[state] = { count: 0, movies: new Set() };
                            }
                            likesByState[state].count += 1;
                            likesByState[state].movies.add(movie.title);
                        }
                    });
                });

                const totalLikes = Object.values(likesByState).reduce((sum, { count }) => sum + count, 0);

                if (totalLikes > 0) {
                    const statePercentages = {};
                    const stateMovies = {};

                    for (const [state, { count, movies }] of Object.entries(likesByState)) {
                        statePercentages[state] = ((count / totalLikes) * 100).toFixed(2);
                        stateMovies[state] = Array.from(movies).join(', ');
                    }

                    setGeographyData({
                        labels: Object.keys(statePercentages).map(state => `${state}: ${stateMovies[state]}`),
                        datasets: [{
                            label: 'Likes by State (%)',
                            data: Object.values(statePercentages),
                            backgroundColor: ['#212121', '#424242', '#616161', '#757575', '#BDBDBD'],
                        }],
                    });
                    setDataAvailable(true);
                } else {
                    setDataAvailable(false);
                }
            } catch (error) {
                console.error('Error fetching user movies:', error);
            }
        };

        fetchUserMovies();
    }, [userId, token]);

    const likesData = {
        labels: movies.map(movie => movie.title),
        datasets: [{ label: 'Likes', data: movies.map(movie => movie.likes), backgroundColor: 'rgba(192, 192, 192, 0.8)' }],
    };

    const commentsData = {
        labels: movies.map(movie => movie.title),
        datasets: [{ label: 'Comments', data: movies.map(movie => movie.comments.length), backgroundColor: 'rgba(160, 160, 160, 0.8)' }],
    };

    const viewsData = {
        labels: movies.map(movie => movie.title),
        datasets: [{ label: 'Views', data: movies.map(movie => movie.views), backgroundColor: 'rgba(224, 224, 224, 0.8)' }],
    };

    const genreCounts = movies.reduce((acc, movie) => {
        movie.genre.forEach(genre => {
            acc[genre] = (acc[genre] || 0) + 1;
        });
        return acc;
    }, {});

    const genreData = {
        labels: Object.keys(genreCounts),
        datasets: [{ label: 'Genres', data: Object.values(genreCounts), backgroundColor: ['#757575', '#BDBDBD', '#9E9E9E', '#616161', '#424242'] }],
    };

    const languageCounts = movies.reduce((acc, movie) => {
        acc[movie.original_language] = (acc[movie.original_language] || 0) + 1;
        return acc;
    }, {});

    const languageData = {
        labels: Object.keys(languageCounts),
        datasets: [{ label: 'Languages', data: Object.values(languageCounts), backgroundColor: 'rgba(189, 189, 189, 0.8)' }],
    };

    const totalMovies = movies.length;
    const averageRating = (movies.reduce((acc, movie) => acc + movie.vote_average, 0) / totalMovies).toFixed(1);

    return (
        <Box
            sx={{
                padding: { xs: 1, md: 2 },
                backgroundColor: '#1E1E1E',
                color: '#E0E0E0',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box sx={{ marginBottom: 2, textAlign: 'center' }}>
                <Typography variant="h6" gutterBottom>Total Movies: {totalMovies}</Typography>
                <Typography variant="h6" gutterBottom>Average Rating: {averageRating}</Typography>
            </Box>

            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box
                        sx={{
                            backgroundColor: '#2C2C2C',
                            borderRadius: 2,
                            flexGrow: 1,
                            padding: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography variant="h6" gutterBottom>Movie Likes</Typography>
                        <Box sx={{ flexGrow: 1 }}>
                            <Bar data={likesData} options={{ maintainAspectRatio: false }} />
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box
                        sx={{
                            backgroundColor: '#2C2C2C',
                            borderRadius: 2,
                            flexGrow: 1,
                            padding: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography variant="h6" gutterBottom>Movie Views</Typography>
                        <Box sx={{ flexGrow: 1 }}>
                            <Bar data={viewsData} options={{ maintainAspectRatio: false }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default AnalyticsPage;
