import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText, Accordion, AccordionSummary, AccordionDetails, Drawer, IconButton, Button, AppBar,
  Tabs,
  Tab
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import Postcommunity from '../ProfilePage/Component-4/Postcommunity';
// import Sizzlers from '../components/common/sizzlers';

const Community = () => {
  const [allPosts, setPosts] = useState([]);  // All posts from API
  const [filteredPosts, setFilteredPosts] = useState([]);  // Filtered posts to display
  const [superusers, setSuperusers] = useState({});
  const [loading, setLoading] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // Drawer toggle state
  const [selectedCategory, setSelectedCategory] = useState(0); // State for selected category

  // Fetch random posts
  const fetchRandomPosts = async () => {
    console.log('Fetching random posts...');
    try {
      const response = await axios.get('https://backend.thecameratales.com/api/admin/posts/random');
      console.log('Posts fetched:', response.data);
      setPosts(response.data); // Save all posts
      setFilteredPosts(response.data); // Initially show all posts
      console.log(setFilteredPosts);
      console.log('All posts state updated:', response.data);
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  // Fetch superusers
  const fetchSuperusers = async () => {
    console.log('Fetching superusers...');
    try {
      const response = await axios.get('https://backend.thecameratales.com/api/admin/superusers');
      console.log('Superusers fetched:', response.data);

      // Group superusers by categories
      const groupedSuperusers = response.data.reduce((acc, user) => {
        user.categories.forEach((category) => {
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push(user);
        });
        return acc;
      }, {});

      setSuperusers(groupedSuperusers);
      console.log('Superusers state updated:', groupedSuperusers);
    } catch (error) {
      console.error('Error fetching superusers:', error);
    } finally {
      setLoading(false);
      console.log('Loading state updated:', false);
    }
  };

  useEffect(() => {
    fetchRandomPosts();
    fetchSuperusers();
  }, []);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    console.log('Drawer toggle state updated:', !isDrawerOpen);
  };

  // Function to filter posts by category
  const filterPostsByCategory = (categoryIndex) => {
    setSelectedCategory(categoryIndex);
    console.log('Selected category updated:', categoryIndex);

    if (categoryIndex === 0) {
      // Show all posts when "All" is selected
      console.log('Showing all posts');
      setFilteredPosts(allPosts);
      console.log('Filtered posts state updated:', allPosts);
    } else {
      const category = Object.keys(superusers)[categoryIndex - 1]; // -1 because "All" is index 0
      console.log(`Filtering by category: ${category}`);
      const usersInCategory = superusers[category] || [];
      const filtered = allPosts.filter(post =>
        usersInCategory.some(user => user._id === post.createdBy._id)
      );
      console.log('Filtered posts:', filtered);
      setFilteredPosts(filtered.length > 0 ? filtered : []);
      console.log('Filtered posts state updated:', filtered);
    }
  };

  // Function to filter posts by user
  const filterPostsByUser = (userId) => {
    console.log(`Filtering posts by user: ${userId}`);

    // Filter posts based on createdBy._id
    const filtered = allPosts.filter(post => post.createdBy._id === userId);

    if (filtered.length > 0) {
      setFilteredPosts(filtered); // Update filtered posts
      console.log('Filtered posts state updated:', filtered);
    } else {
      console.log(`No posts found for userId: ${userId}`);
      setFilteredPosts([]); // Optionally clear if no posts found
      console.log('Filtered posts state cleared');
    }
  };

  return (
    <Box sx={{ marginTop: "10vh" }}>
      {/* <Sizzlers /> */}

      <AppBar position="static" sx={{ backgroundColor: '#1a1a1a' }}>
        <Tabs
          value={selectedCategory}
          onChange={(e, newValue) => filterPostsByCategory(newValue)}
          textColor="inherit"
          TabIndicatorProps={{
            style: {
              backgroundColor: 'grey', // Make the tab indicator (underline) white
            },
          }}
          sx={{
            '& .MuiTab-root': {
              border: '1px solid white', // Add white border around each Tab
              borderRadius: '8px', // Optional: add rounded corners
              margin: '0 8px', // Optional: space between tabs
            },
            '& .Mui-selected': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)', // Optional: slightly highlight selected tab
            },
          }}
        >
          <Tab label="All" /> {/* Add "All" Tab */}
          {Object.keys(superusers).map((category, index) => (
            <Tab key={index} label={category} />
          ))}
        </Tabs>
      </AppBar>

      {/* Toggle Button for Drawer (visible on mobile screens) */}
      <IconButton
        edge="start"
        aria-label="menu"
        onClick={toggleDrawer}
        sx={{ display: { xs: 'block', md: 'none', marginLeft: '4vw' }, marginBottom: '1rem', color: 'white' }}
      >
        <MenuIcon />
      </IconButton>

      {/* Responsive Drawer for Sidebar */}
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        sx={{
          display: { xs: 'block', md: 'none' }, // Show drawer on small screens
        }}
      >
        <Box sx={{ width: 250, backgroundColor: '#1a1a1a', color: 'white', height: '100%' }}>
          <IconButton onClick={toggleDrawer} sx={{ color: 'white' }}>
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}
          >
            Artists
          </Typography>
          <List>
            {loading ? (
              <Typography>Loading superusers...</Typography>
            ) : (
              Object.keys(superusers).length > 0 ? (
                Object.keys(superusers).map((category) => (
                  <Accordion key={category} sx={{ backgroundColor: '#1a1a1a', color: 'white' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                      aria-controls={`${category}-content`}
                      id={`${category}-header`}
                    >
                      <Typography variant="subtitle1">{category}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {superusers[category].map((user) => (
                        <ListItem
                          key={user._id}
                          button
                          onClick={() => filterPostsByUser(user._id)}
                        >
                          <ListItemAvatar>
                            <Avatar src={`https://backend.thecameratales.com/uploads/${user.profilePicture}`} alt={`${user.firstName} ${user.lastName}`} />
                          </ListItemAvatar>
                          <ListItemText primary={`${user.firstName} ${user.lastName}`} />
                        </ListItem>
                      ))}
                      <Divider />
                    </AccordionDetails>
                  </Accordion>
                ))
              ) : (
                <Typography>No superusers found.</Typography>
              )
            )}
          </List>
        </Box>
      </Drawer>

      {/* Main Layout (Visible on larger screens) */}
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {/* Sidebar for Larger Screens */}
        <Box sx={{ width: '25%', marginRight: '2%', display: { xs: 'none', md: 'block' } }}>
          <Typography variant="h6" gutterBottom style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', color: 'white' }}>Artists</Typography>
          <List>
            {loading ? (
              <Typography>Loading superusers...</Typography>
            ) : (
              Object.keys(superusers).length > 0 ? (
                Object.keys(superusers).map((category) => (
                  <Accordion key={category} sx={{ backgroundColor: '#1a1a1a', color: 'white' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                      aria-controls={`${category}-content`}
                      id={`${category}-header`}
                    >
                      <Typography variant="subtitle1">{category}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {superusers[category].map((user) => (
                        <ListItem
                          key={user._id}
                          button
                          onClick={() => filterPostsByUser(user._id)}
                        >
                          <ListItemAvatar>
                            <Avatar src={`https://backend.thecameratales.com/uploads/${user.profilePicture}`} alt={`${user.firstName} ${user.lastName}`} />
                          </ListItemAvatar>
                          <ListItemText primary={`${user.firstName} ${user.lastName}`} />
                        </ListItem>
                      ))}
                      <Divider />
                    </AccordionDetails>
                  </Accordion>
                ))
              ) : (
                <Typography>No superusers found.</Typography>
              )
            )}
          </List>
        </Box>

        {/* Main Post Content */}
        <Box sx={{ width: { xs: '100%', md: '70%' } }}>
          <Postcommunity
            posts={filteredPosts} // Show filtered posts here
            setPosts={setPosts}
          />

          {/* Button to show all posts */}
          <Button
            onClick={() => {
              setFilteredPosts(allPosts);
              console.log('Show All Posts button clicked, posts state reset to all posts');
            }}
            variant="outlined"
            sx={{
              margin: '1rem 0',
              color: 'white', // Text color
              borderColor: 'white', // Outline color
              '&:hover': {
                borderColor: 'white', // Keep the outline white on hover as well
              },
            }}
          >
            Show All Posts
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Community;
