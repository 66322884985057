// src/components/Login.js
import { useState, useContext } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import { signInWithRedirect, GoogleAuthProvider, getRedirectResult } from "firebase/auth";
import { auth } from "../firebase/firebaseconfig";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebaseconfig";
import { UserContext } from "../../../Context/UserContext";

const Login = () => {
  const [data, setData] = useState({ email: "", password: "" });
  const [error, setError] = useState("");
  const { login } = useContext(UserContext);
  const navigate = useNavigate();

  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = `${apiBaseURL}/api/auth`;
      const { data: res } = await axios.post(url, data);
      login(res.user);
      localStorage.setItem("token", res.token);
      navigate("/");
      window.location.reload(); 
    } catch (error) {
      if (error.response && error.response.status >= 400 && error.response.status <= 500) {
        setError(error.response.data.message);
      }
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithRedirect(auth, provider);

      const result = await getRedirectResult(auth);
      if (result) {
        const { user } = result;
        const idToken = await user.getIdToken();
        localStorage.setItem("token", idToken);

        const userData = {
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
        };

        await setDoc(doc(db, "users", user.uid), userData);

        login(userData);
        navigate("/Main");
        window.location.reload();
      }
    } catch (error) {
      console.error("Error signing in with Google:", error.message);
      setError("Error signing in with Google");
    }
  };

  return (
    <>
      <div className={styles.login_container}>
        <div className={styles.surround_login_container}>
          <div className={styles.login_form_container}>
            <div className={styles.left}>
              <form className={styles.form_container} onSubmit={handleSubmit}>
                <h1>Login to Your Account</h1>
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={handleChange}
                  value={data.email}
                  required
                  className={styles.input}
                />
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  onChange={handleChange}
                  value={data.password}
                  required
                  className={styles.input}
                />
                {error && <div className={styles.error_msg}>{error}</div>}
                <button type="submit" className={styles.green_btn}>
                  Sign In
                </button>
                <Link to="/forgetpassword" style={{ color: 'blue', textDecoration: 'underline' }}>
                  Forgot Password?
                </Link>
              </form>
            </div>
            <div className={styles.right}>
              <h1 style={{ margin: '10px', textAlign: "center" }}>Create account</h1>
              <Link to="/signup">
                <button type="button" className={styles.white_btn}>
                  Sign Up
                </button>
              </Link>
              <div>
                <button onClick={handleGoogleSignIn} className={styles.green_btn}>
                  Sign In with Google
                </button>
                {/* Add the Forgot Password link here */}
              <div className={styles.forgot_password}>
                
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
