import { Grid } from "@mui/material";
import MediaItemTwo from "./MediaItemtwo";
import PeopleItem from "./MediaPeopleItem"; // Create a new component for people

const MediaGrid = ({ medias, mediaType }) => {
  return (
    <Grid container spacing={1} sx={{ marginRight: "-8px!important", overflow: 'hidden' }}>
      {medias.map((media, index) => (
        <Grid
          item
          xs={6}
          sm={4}
          md={3}
          lg={2}
          xl={2}
          key={index}
          sx={{
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          {mediaType === "people" ? (
            <PeopleItem media={media} /> // Render a different component for people
          ) : (
            <MediaItemTwo media={media} mediaType={mediaType} isMediaGrid />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default MediaGrid;
