import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Box, Card, CardContent, Typography, Link, Divider, CircularProgress, Button, Switch, FormControlLabel, Grid } from '@mui/material';
import { Email, Phone, LocationOn, Language, Twitter, Instagram, Movie, Star, LinkedIn } from '@mui/icons-material';

const UserProfile = () => {
    const { userId } = useParams();
    const [userData, setUserData] = useState(null);
    const [privacySettings, setPrivacySettings] = useState(null);
    const [showMoreBiography, setShowMoreBiography] = useState(false);
    const [loggedInUser, setLoggedInUser] = useState(null);

    // Fetch logged-in user data from localStorage
    useEffect(() => {
        const userFromLocalStorage = JSON.parse(localStorage.getItem('user'));  // Assuming the user data is saved here
        setLoggedInUser(userFromLocalStorage);
    }, []);

    // Fetch user data and privacy settings
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://backend.thecameratales.com/api/admin/users/${userId}`);
                setUserData(response.data);
                setPrivacySettings(response.data.privacySettings || {});
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };
        if (userId) fetchData();
    }, [userId]);

    const handlePrivacyChange = async (field) => {
        if (loggedInUser && (loggedInUser.id === userId )) {
            const updatedPrivacy = { ...privacySettings, [field]: !privacySettings[field] };
            console.log("Updated Privacy Settings: ", updatedPrivacy);
            setPrivacySettings(updatedPrivacy);
            try {
                const response = await axios.put(`https://backend.thecameratales.com/api/admin/users/${userId}/privacy`, { privacySettings: updatedPrivacy });
                console.log("Updated Privacy in Server:", response.data);
            } catch (error) {
                console.error("Error updating privacy settings:", error);
            }
        }
    };


    if (!userData) return <Box display="flex" justifyContent="center" alignItems="center" height="100vh"><CircularProgress /></Box>;

    const {
        name, email, contactInfo, biography, awards = [],
        nationality, gender, categories = [], website, imdbProfile, twitterHandle, instagramHandle,
        socialLinks = []
    } = userData;

    const toggleBiography = () => setShowMoreBiography(!showMoreBiography);

     // Map social media types to icons
     const socialIcons = {
        Instagram: "Instagram",
        LinkedIn: "LinkedIn",
        Twitter: "Twitter",
    };

    return (
        <Box sx={{ minWidth: '300px', mt: 4, p: 0, maxHeight: '55vh' }}>
            <Card
                variant="outlined"
                sx={{
                    backgroundColor: '#15171a',
                    color: 'white',
                    maxHeight: '47vh',
                    p: 2,
                    overflow: 'auto',
                    '&::-webkit-scrollbar': { width: '8px' },
                    '&::-webkit-scrollbar-track': { backgroundColor: '#2c2c3c', borderRadius: '10px' },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#5c6bc0',
                        borderRadius: '10px',
                        border: '2px solid transparent',
                        backgroundClip: 'content-box',
                    },
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#5c6bc0 #2c2c3c',
                }}
            >
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={9}>
                            <Typography variant="h4" gutterBottom>{name}</Typography>
                            {categories.length > 0 && (
                                <Typography variant="subtitle1">{categories.join(", ")}</Typography>
                            )}
                            <Divider sx={{ my: 2, bgcolor: 'primary.main' }} />
                        </Grid>
                    </Grid>

                    {/* Contact Information */}
                    <Typography variant="h6" gutterBottom>Contact Information</Typography>

                    {/* Email */}
                    {((loggedInUser && (loggedInUser.id === userId )) || (privacySettings.email)) && (
                        <Box display="flex" alignItems="center" gap={1}>
                            <Email /> <Typography>{email}</Typography>
                            {(loggedInUser && (loggedInUser.id === userId )) && (
                                <FormControlLabel
                                    control={<Switch checked={privacySettings.email} onChange={() => handlePrivacyChange("email")} />}
                                    label="Public"
                                    sx={{
                                        '& .MuiSwitch-thumb': {
                                            backgroundColor: privacySettings.email ? 'white' : 'gray',
                                        },
                                        '& .MuiSwitch-track': {
                                            backgroundColor: privacySettings.email ? '#ccc' : '#ccc',
                                        },
                                    }}
                                />
                            )}
                        </Box>
                    )}

                    {/* Phone */}
                    {((loggedInUser && (loggedInUser.id === userId )) || (privacySettings.phone && contactInfo?.phone)) && (
                        <Box display="flex" alignItems="center" gap={1}>
                            <Phone /> <Typography>{contactInfo?.phone}</Typography>
                            {(loggedInUser && (loggedInUser.id === userId )) && (
                                <FormControlLabel
                                    control={<Switch checked={privacySettings.phone} onChange={() => handlePrivacyChange("phone")} />}
                                    label="Public"
                                    sx={{
                                        '& .MuiSwitch-thumb': {
                                            backgroundColor: privacySettings.phone ? 'white' : 'gray',
                                        },
                                        '& .MuiSwitch-track': {
                                            backgroundColor: privacySettings.phone ? '#ccc' : '#ccc',
                                        },
                                    }}
                                />
                            )}
                        </Box>
                    )}

                    {/* Address */}
                    {((loggedInUser && (loggedInUser.id === userId )) || (privacySettings.address && contactInfo?.address)) && (
                        <Box display="flex" alignItems="center" gap={1}>
                            <LocationOn />
                            <Typography>{`${contactInfo?.address?.street}, ${contactInfo?.address?.city}, ${contactInfo?.address?.state}`}</Typography>
                            {(loggedInUser && (loggedInUser.id === userId )) && (
                                <FormControlLabel
                                    control={<Switch checked={privacySettings.address} onChange={() => handlePrivacyChange("address")} />}
                                    label="Public"
                                    sx={{
                                        '& .MuiSwitch-thumb': {
                                            backgroundColor: privacySettings.address ? 'white' : 'gray',
                                        },
                                        '& .MuiSwitch-track': {
                                            backgroundColor: privacySettings.address ? '#ccc' : '#ccc',
                                        },
                                    }}
                                />
                            )}
                        </Box>
                    )}

                    {/* Biography */}
                    {biography && ((loggedInUser && (loggedInUser.id === userId )) || privacySettings.biography) && (
                        <>
                            <Divider sx={{ my: 3, bgcolor: 'primary.main' }} />
                            <Typography variant="h6" gutterBottom>Biography</Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    whiteSpace: 'pre-wrap', // This ensures newlines are respected
                                    wordWrap: 'break-word', // This ensures text wraps correctly within the container
                                }}
                            >
                                {showMoreBiography ? biography : `${biography.slice(0, 100)}...`}
                            </Typography>
                            <Button onClick={toggleBiography} color="secondary">
                                {showMoreBiography ? 'Read Less' : 'Read More'}
                            </Button>
                            {(loggedInUser && (loggedInUser.id === userId )) && (
                                <FormControlLabel
                                    control={<Switch
                                        checked={privacySettings.biography}
                                        onChange={() => handlePrivacyChange("biography")}
                                        sx={{
                                            '& .MuiSwitch-thumb': {
                                                backgroundColor: privacySettings.biography ? 'white' : 'gray',
                                            },
                                            '& .MuiSwitch-track': {
                                                backgroundColor: privacySettings.biography ? '#ccc' : '#ccc',
                                            },
                                        }}
                                    />}
                                    label="Public"
                                />
                            )}
                        </>
                    )}

                    {/* Professional Information */}
                    <Divider sx={{ my: 3, bgcolor: 'primary.main' }} />
                    <Typography variant="h6" gutterBottom>Professional Information</Typography>
                    {nationality && ((loggedInUser && (loggedInUser.id === userId)) || privacySettings.nationality) && (
                        <Box display="flex" alignItems="center">
                            <Typography><strong>Nationality:</strong> {nationality}</Typography>
                            {(loggedInUser && (loggedInUser.id === userId)) && (
                                <FormControlLabel
                                    control={<Switch checked={privacySettings.nationality} onChange={() => handlePrivacyChange("nationality")} />}
                                    label="Public"
                                    sx={{
                                        '& .MuiSwitch-thumb': {
                                            backgroundColor: privacySettings.nationality ? 'white' : 'gray',
                                        },
                                        '& .MuiSwitch-track': {
                                            backgroundColor: privacySettings.nationality ? '#ccc' : '#ccc',
                                        },
                                    }}
                                />
                            )}
                        </Box>
                    )}
                    {gender && ((loggedInUser && (loggedInUser.id === userId)) || privacySettings.gender) && (
                        <Box display="flex" alignItems="center">
                            <Typography><strong>Gender:</strong> {gender}</Typography>
                            {(loggedInUser && (loggedInUser.id === userId)) && (
                                <FormControlLabel
                                    control={<Switch checked={privacySettings.gender} onChange={() => handlePrivacyChange("gender")} />}
                                    label="Public"
                                    sx={{
                                        '& .MuiSwitch-thumb': {
                                            backgroundColor: privacySettings.gender ? 'white' : 'gray',
                                        },
                                        '& .MuiSwitch-track': {
                                            backgroundColor: privacySettings.gender ? '#ccc' : '#ccc',
                                        },
                                    }}
                                />
                            )}
                        </Box>
                    )}

                    {/* Social Links */}
                    <Divider sx={{ my: 3, bgcolor: 'primary.main' }} />
                    <Typography variant="h6" gutterBottom>Social Links</Typography>
                    {website && (
                        <Box display="flex" alignItems="center" gap={1}>
                            <Language />
                            <Link href={website} style={{color:'cornflowerblue'}} underline="hover">Website</Link>
                        </Box>
                    )}
                    {imdbProfile && (
                        <Box display="flex" alignItems="center" gap={1}>
                            <Movie />
                            <Link href={imdbProfile} style={{color:'cornflowerblue'}} underline="hover">IMDb Profile</Link>
                        </Box>
                    )}
                    {twitterHandle && (
                        <Box display="flex" alignItems="center" gap={1}>
                            <Twitter />
                            <Link href={`https://twitter.com/${twitterHandle}`} style={{color:'cornflowerblue'}} underline="hover">@{twitterHandle}</Link>
                        </Box>
                    )}
                    {instagramHandle && (
                        <Box display="flex" alignItems="center" gap={1}>
                            <Instagram />
                            <Link href={`https://instagram.com/${instagramHandle}`} style={{color:'cornflowerblue'}} underline="hover">@{instagramHandle}</Link>
                        </Box>
                    )}

                    {/* Social Links */}
                    <Divider sx={{ my: 3, bgcolor: 'primary.main' }} />
                    <Typography variant="h6" gutterBottom>Work Links</Typography>
                    {socialLinks.map((link) => (
                        <Box key={link._id} display="flex" alignItems="center" gap={1}>
                            {socialIcons[link.type] || <span>{link.type}</span>}
                            <Link href={link.link} style={{color:'cornflowerblue'}} underline="hover">{link.type}</Link>
                        </Box>
                    ))}

                    {/* Awards */}
                    {awards.length > 0 && (
                        <>
                            <Divider sx={{ my: 3, bgcolor: 'primary.main' }} />
                            <Typography variant="h6" gutterBottom>Awards</Typography>
                            <ul>
                                {awards.map((award, index) => (
                                    <li key={index}>
                                        {award.name ? award.name : 'No Award Added'} ({award.year ? award.year : 'No Year Added'})
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
};

export default UserProfile;
