import { createSlice } from "@reduxjs/toolkit";

const keySkillsSlice = createSlice({
    name: "keySkills",
    initialState: { skills: ["", "", ""] },
    reducers: {
      addNewSkills(state) {
        state.skills.push("");
      },
      editSkill(state, action) {
        const { id, value } = action.payload;
        state.skills = state.skills.map((skill, index) =>
          index === id ? value : skill
        );
      },
      deleteSkill(state, action) {
        state.skills.splice(action.payload, 1);
      },
    },
  });
  

export const { addNewSkills, editSkill, deleteSkill } = keySkillsSlice.actions;
export const keySkillsReducer = keySkillsSlice.reducer;
