import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import axios from 'axios';
import Container from "../components/common/Container";
import MediaSlide from "../components/common/MediaSlide";
import './UserLandingPage.css'; // Import your CSS file

const UserLandingPage = () => {
  const [userData, setUserData] = useState({});
  const [profilePicture, setProfilePicture] = useState('');
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [subscription, setSubscription] = useState({});
  const token = localStorage.getItem('token');
  const user = JSON.parse(localStorage.getItem('user'));
  const userId = user?.id;

  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  const navigate = useNavigate();  // Initialize useNavigate hook

  useEffect(() => {
    if (!userId) return;

    const fetchData = async () => {
      try {
        // Fetch user profile data from the API
        const profileRes = await axios.get(`${apiBaseURL}/api/users/${userId}`, {
          headers: { 'x-auth-token': token },
        });
        const userProfile = profileRes.data;
        setProfilePicture(userProfile.profilePicture);
        setUserData(userProfile); // Update userData with API response

        const fetchFollowersAndFollowing = async () => {
          try {
            const [followersRes, followingRes] = await Promise.all([
              axios.get(`${apiBaseURL}/api/users/${userId}/followers`, {
                headers: { 'x-auth-token': token },
              }),
              axios.get(`${apiBaseURL}/api/users/${userId}/following`, {
                headers: { 'x-auth-token': token },
              }),
            ]);

            setFollowers(followersRes.data.followers);
            setFollowing(followingRes.data.following);
          } catch (err) {
            console.error("Error fetching followers/following:", err);
          }
        };

        fetchFollowersAndFollowing();

        setSubscription(userProfile.subscription);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [userId, token]);

  const handleNavigateToCMS = () => {
    navigate('/cmsform');  // Navigate to /cmsform on button click
  };

  return (
    <div className="user-landing-page" style={{ marginBottom: '10px', paddingTop: '10vh', paddingBottom: '40vh' }}>
      <div className="profile-section">
        <img src={`${apiBaseURL}/uploads/${profilePicture}`} alt="" className="profile-pic" />
        <div className="profile-info">
          <h2>{userData.firstName} {userData.lastName}</h2>
          <p>Email: {userData.email}</p>
          {/* <p>Phone: {userData.contactInfo?.phone}</p> */}
        </div>
      </div>

      <div className="follow-section">
        {/* <div className="follow-card">
          <h3>Followers</h3>
          <p>{followers.length}</p>
        </div> */}
        <div className="follow-card">
          <h3>Following</h3>
          <p>{following.length}</p>
        </div>
      </div>

      {/* <div className="favorites-section">
        <Container header="Favorites">
          <MediaSlide mediaType="favorites" userId={userId} />
        </Container>
      </div> */}
      {/* <div className="favorites-section">
        {/* <Container header="Watchlist">
          <MediaSlide mediaType="viewed" userId={userId} />
        </Container> */}
      {/* </div> */}

      <div className="subscription-section">
        <div className="subscription-card">
          <h3>Subscription Plan</h3>
          <p><strong>Plan:</strong> {subscription.plan}</p>
          {/* <p><strong>Start Date:</strong> {new Date(subscription.startDate).toLocaleDateString()}</p>
          <p><strong>End Date:</strong> {new Date(subscription.endDate).toLocaleDateString()}</p> */}
          <p><strong>Start Date:</strong> Comming soon...</p>
          <p><strong>End Date:</strong> Comming soon...</p>
        </div>
      </div>
      {/* Add button to navigate to /cmsform */}
      <div className="cms-form-button" style={{ position: 'relative', marginTop: '20px' }}>
        <button
          onClick={handleNavigateToCMS}
          className="navigate-button"
          style={{
            padding: '10px',
            backgroundColor: '#ff6347',
            color: 'white',
            margin: '5px',
            width: '80%',
          }}
        >
          Register As Artist
        </button>
      </div>
    </div>
  );
};

export default UserLandingPage;
