import { Card, CardContent, Typography, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PeopleItem = ({ media }) => {

    const navigate = useNavigate();

    const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

    // Function to handle card click
  const handleCardClick = () => {
    navigate(`/users/${media._id}`); // Adjust the route according to your routing setup
  };

  return (
    <Card onClick={handleCardClick} sx={{ cursor: 'pointer' }}>
      <CardContent>
        <Avatar
           src={media.profilePicture ? `${apiBaseURL}/uploads/${media.profilePicture}` : "/default-avatar.png"} // Use a default image if none is provided
          alt={`${media.firstName} ${media.lastName}`}
          sx={{ width: 56, height: 56 }}
        />
        <Typography variant="h6">
          {media.firstName} {media.lastName}
        </Typography>
        <Typography variant="body2">{media.categories}</Typography>
        {/* You can add more details as needed */}
      </CardContent>
    </Card>
  );
};

export default PeopleItem;
