import React, { useState, useCallback } from 'react';
import { Box, Button, TextField, Avatar, Typography, Paper, Stack, Snackbar, Alert, Dialog } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import SendIcon from '@mui/icons-material/Send';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from './getCroppedImg'; // Ensure this function returns a Blob or File

const PostForm = ({ loggedInUser, user, onCreatePost, uploadProgress }) => {
  const isCurrentUser = loggedInUser?.id?.toString() === user?._id?.toString();
  const isSuperUser = user?.role === 'super-user';

  const [media, setMedia] = useState(null);
  const [preview, setPreview] = useState(null);
  const [caption, setCaption] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [isCropping, setIsCropping] = useState(false);

  // Handle media file change
  const handleMediaChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith('video/')) {
        const video = document.createElement('video');
        video.src = URL.createObjectURL(file);
        video.onloadedmetadata = () => {
          if (video.duration > 61) {
            setError('The video must be 60 seconds or less.');
            setMedia(null);
            setPreview(null);
          } else {
            setMedia(file);
            setPreview(URL.createObjectURL(file));
            setError('');
          }
        };
      } else {
        setMedia(file);
        setPreview(URL.createObjectURL(file));
        setIsCropping(true); // Trigger cropping modal
        setError('');
      }
    }
  };

  // Handle crop complete
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // Save the cropped image
  const handleCropSave = async () => {
    try {
      if (!croppedAreaPixels || !preview) {
        setError('Invalid crop data');
        return;
      }

      // Ensure getCroppedImg returns a blob or file type
      const croppedBlob = await getCroppedImg(preview, croppedAreaPixels);
      const croppedUrl = URL.createObjectURL(croppedBlob); // Generate a URL for the cropped image
      setMedia(croppedBlob);
      setPreview(croppedUrl); // Update preview to show cropped image
      setIsCropping(false);
      setError('');
    } catch (err) {
      setError('Error cropping the image.');
      console.error(err); // Log the error to understand what might be going wrong
    }
  };


  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!caption) {
      setError('Caption is required');
      return;
    }
    if (!media) {
      setError('Media is required');
      return;
    }

    const formData = new FormData();
    formData.append('media', media);
    formData.append('caption', caption);
    formData.append('mediaType', media.type.startsWith('video/') ? 'video' : 'image');

    onCreatePost(formData)
      .then(() => {
        setMedia(null);
        setPreview(null);
        setCaption('');
        setSuccess(true);
        setError('');
      })
      .catch(() => {
        setError('An error occurred while posting');
      });
  };

  return (
    <>
      {isCurrentUser && isSuperUser && (
        <Paper elevation={3} sx={{ p: 3, borderRadius: 2, background: 'linear-gradient(to right, #15171a, #15171a, #15171a)', mb: 3, mt: 2 }}>
          <Stack direction="row" alignItems="center" spacing={2} mb={2}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#D3D3D3' }}>Hey {loggedInUser.firstName} Promote Yourself</Typography>
          </Stack>
          <form onSubmit={handleSubmit}>
            <TextField
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              placeholder="Write something..."
              value={caption}
              onChange={(event) => setCaption(event.target.value)}
              sx={{ mb: 2, bgcolor: '#D3D3D3', borderRadius: 1 }}
              error={Boolean(error)}
              helperText={error}
            />
            {uploadProgress > 0 && (
              <Box>
                <Typography style={{ color: 'green' }}>Uploading: {uploadProgress}%</Typography>
                <progress value={uploadProgress} max="100"></progress>
              </Box>
            )}
            <p style={{ color: 'white' }}>Ensure post dimensions to be 859X787 pxs</p>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              alignItems="center"
              spacing={2}
              mb={2}
            >
              <label htmlFor="upload-media">
                <input
                  id="upload-media"
                  name="upload-media"
                  type="file"
                  accept="image/*,video/*"
                  onChange={handleMediaChange}
                  style={{ display: 'none' }}
                />
                <Button
                  variant="outlined"
                  color="primary"
                  component="span"
                  style={{ backgroundColor: '#D3D3D3', fontWeight: 'bolder' }}
                  startIcon={media?.type?.startsWith('video/') ? <VideoLibraryIcon sx={{ color: 'black' }} /> : <AddPhotoAlternateIcon sx={{ color: 'black' }} />}
                >
                  {media?.type?.startsWith('video/') ? 'Change Media' : 'Add Media'}
                  {/* .................................................................................. */}
                </Button>
              </label>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                endIcon={<SendIcon sx={{
                  color: 'black', '&:hover': {
                    color: 'white', // Change text color on hover
                  },
                }} />}
                sx={{
                  ml: 'auto', backgroundColor: '#D3D3D3', color: 'black', fontWeight: 'bolder',
                  '&:hover': {
                    color: 'white', // Change text color on hover
                  },
                }}
              >
                Post
              </Button>
              {preview && media?.type?.startsWith('image/') && !isCropping && (
                <Box sx={{ position: 'relative', width: 100, height: 100 }}>
                  <img src={preview} alt="Preview" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 1 }} />
                </Box>
              )}
              {preview && media?.type?.startsWith('video/') && (
                <Box sx={{ position: 'relative', width: 150, height: 150 }}>
                  <video width="150" height="150" controls controlsList="nodownload">
                    <source src={preview} type={media.type} />
                  </video>
                </Box>
              )}
            </Stack>
          </form>
          <Snackbar open={success} autoHideDuration={6001} onClose={() => setSuccess(false)}>
            <Alert onClose={() => setSuccess(false)} severity="success">
              Post created successfully!
            </Alert>
          </Snackbar>
          <Snackbar open={Boolean(error)} autoHideDuration={6001} onClose={() => setError('')}>
            <Alert onClose={() => setError('')} severity="error">
              {error}
            </Alert>
          </Snackbar>
        </Paper>
      )}

      {/* Cropping Dialog */}
      {isCropping && (
        <Dialog open={isCropping} onClose={() => setIsCropping(false)} maxWidth="md" fullWidth>
          <Box sx={{ position: 'relative', width: '100%', height: 400, background: '#333' }}>
            <Cropper
              image={preview}
              crop={crop}
              zoom={zoom}
              aspect={835 / 761} // Adjust aspect ratio as needed; try other values like 16 / 9 for a wider crop
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </Box>
          <Stack direction="row" justifyContent="space-between" p={2}>
            <Button onClick={() => setIsCropping(false)}>Cancel</Button>
            <Button variant="contained" color="primary" onClick={handleCropSave}>
              Save Crop
            </Button>
          </Stack>
        </Dialog>
      )}
    </>
  );
};
// 

export default PostForm;