import { NavLink } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import {
  Home,
  Movie,
  Tv,
  Category,
  PlaylistPlay,
  QueueMusic,
  Favorite,
  WatchLater,
  VideoLibrary,
  ExpandMore,
  ExpandLess,
  Search,
  Settings,
  TheaterComedy,
  CurrencyRupee,
  Groups,
} from "@mui/icons-material";
import {
  useMediaQuery,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useState } from "react";
import styles from './SideBarDiv.module.css';

const routes = [
  { path: "/search", name: "Search", icon: Search },
  { path: "/", name: "Home", icon: Home },
];

const categories = [
  { path: "/movie", name: "Movies", icon: Movie },
  { path: "/fridaytalkies", name: "Friday Talkies", icon: TheaterComedy },
  { path: "/webseries", name: "Web Series", icon: PlaylistPlay },
  { path: "/shows", name: "Web Shows", icon: Tv },
  { path: "/musicvideos", name: "Music Videos", icon: QueueMusic },
  { path: "/toptalesthisweek", name: "Top Tales This Week", icon: Favorite },
  { path: "/shortfilms", name: "Short Films", icon: VideoLibrary },
];

const additionalRoutes = [
  { path: "/community", name: "Community", icon: Groups },
  { path: "/subscription", name: "Subscription", icon: CurrencyRupee },
  { path: "/profilesettings", name: "Settings", icon: Settings },
];

const SideBarDiv = ({ isOpen, toggleSidebar }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [categoriesOpen, setCategoriesOpen] = useState(false);

  const showAnimation = {
    hidden: { width: 0, opacity: 0, transition: { duration: 0.5 } },
    show: { opacity: 1, width: "auto", transition: { duration: 0.5 } },
  };

  const handleCategoriesToggle = () => setCategoriesOpen(!categoriesOpen);

  return (
    <>
      {/* Backdrop for blurring the main content */}
      {isOpen && (
        <div
          className={styles.backdrop}
          onClick={toggleSidebar}
        ></div>
      )}
      <motion.div
        className={styles.sidebar}
        initial={{ width: "60px", margin: 0 }}
        animate={{
          width: isSmallScreen ? (isOpen ? "250px" : "0px") : (isOpen ? "250px" : "60px"),
        }}
        transition={{ duration: 0.5, type: "spring", damping: 10 }}
      >
        <section className="routes" style={{ marginTop: "15vh" }}>
          {routes.map((route, index) => {
            const Icon = route.icon;
            return (
              <NavLink
                to={route.path}
                key={index}
                className={`${styles.link} ${isOpen ? styles.active : ""}`}
              >
                <Icon style={{ color: "white", marginRight: "5px", fontSize: "20px" }} />
                <AnimatePresence>
                  {isOpen && (
                    <motion.div
                      className={styles.link_text}
                      variants={showAnimation}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                    >
                      {route.name}
                    </motion.div>
                  )}
                </AnimatePresence>
              </NavLink>
            );
          })}

          {/* Categories Dropdown */}
          <List component="nav" style={{ padding: 0 }}>
            <ListItem button onClick={handleCategoriesToggle} className={styles.categoriesToggle}>
              <ListItemIcon>
                <Category style={{ color: "white", fontSize: "20px" }} />
              </ListItemIcon>
              <AnimatePresence>
                {isOpen && (
                  <motion.div
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                  >
                    <ListItemText
                      primary="Categories"
                      style={{ color: "white", fontWeight: "bolder", marginLeft: "-1vw" }}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
              {categoriesOpen ? <ExpandLess style={{ color: "white" }} /> : <ExpandMore style={{ color: "white" }} />}
            </ListItem>
            <Collapse in={categoriesOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {categories.map((category, index) => (
                  <ListItem
                    button
                    key={index}
                    component={NavLink}
                    to={category.path}
                    className={`${styles.collapseItem} ${isOpen ? styles.active : ""}`}
                  >
                    <ListItemIcon>
                      <category.icon style={{ color: "white", fontSize: "20px" }} />
                    </ListItemIcon>
                    <ListItemText primary={category.name} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </List>

          {/* Additional Routes after Categories */}
          {additionalRoutes.map((route, index) => {
            const Icon = route.icon;
            return (
              <NavLink
                to={route.path}
                key={index}
                className={`${styles.link} ${isOpen ? styles.active : ""}`}
              >
                <Icon style={{ color: "white", marginRight: "5px", fontSize: "20px" }} />
                <AnimatePresence>
                  {isOpen && (
                    <motion.div
                      className={styles.link_text}
                      variants={showAnimation}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                    >
                      {route.name}
                    </motion.div>
                  )}
                </AnimatePresence>
              </NavLink>
            );
          })}
        </section>
      </motion.div>
    </>
  );
};

export default SideBarDiv;
