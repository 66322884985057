import { Paper, Stack, Button, Box, IconButton, Divider, Typography } from '@mui/material';
import { Facebook, Twitter, Instagram, LinkedIn } from '@mui/icons-material';
import { Link, Navigate } from "react-router-dom";
import Logo from './Logo';
import menuConfigs from "../../configs/menu.configs";
import { useNavigate } from 'react-router-dom';



const Footer = () => {
  const navigate = useNavigate();
  return (
    <Paper square={true} sx={{ backgroundColor: "rgba(0, 0, 0)", padding: "2rem", borderTop:"1px solid white" }}>
      <Stack
        alignItems="center"
        justifyContent="space-between"
        direction={{ xs: "column", md: "row " }}
        spacing={2}
        sx={{ height: "max-content" }}
      >
        <Box>
  <Logo />
  <Divider sx={{ my: 2 }} />
  <Box style={{ color: "white" }}>
    {/* Filter the menuConfigs.main array to only include the "Home" item */}
    {menuConfigs.main
      .filter(item => item.display === "home")
      .map((item, index) => (
        <Button
          key={index}
          sx={{ color: "inherit", marginLeft:"40px" }}
          component={Link}
          to={item.path}
        >
          Back To Home
        </Button>
      ))}
      {/* <Button
            variant="contained"
            sx={{
              alignSelf: 'center',
              mt: 3,
              backgroundColor: 'black',
              color: 'white',
              marginLeft:"40px" ,
              '&:hover': { backgroundColor: 'black' },
            }}
            onClick={() => navigate(`/contactus`)}
          >
            Contact Us
          </Button> */}
  </Box>
</Box>


        {/* Social Media Icons */}
        <Stack direction="column" alignItems="Left">
          <Box>
            <IconButton color="primary" component="a" href="https://www.facebook.com/thecameratales" style={{color:"white"}}>
              <Facebook />
            </IconButton>
          </Box>
          <Box>
            <IconButton color="primary" component="a" href="https://www.linkedin.com/company/thecameratales" style={{color:"white"}}>
              <LinkedIn />
            </IconButton>
          </Box>
          <Box>
            <IconButton color="primary" component="a" href="https://www.instagram.com/thecameratales.ott" style={{color:"white"}}>
              <Instagram />
            </IconButton>
          </Box>
        </Stack>

        {/* Website Description and Our Story */}
        <Box>
          <Typography variant="h6" gutterBottom align="center" sx={{ color: "white" }}>

            <Button
            variant="contained"
            sx={{
              alignSelf: 'center',
              mt: 0,
              backgroundColor: 'black',
              color: 'white',
              marginLeft:"40px" ,
              '&:hover': { backgroundColor: 'black' },
            }}
            onClick={() => navigate(`/aboutus`)}
          >
            About Us
          </Button>

            <Button
            variant="contained"
            sx={{
              alignSelf: 'center',
              mt: 0,
              backgroundColor: 'black',
              color: 'white',
              marginLeft:"40px" ,
              '&:hover': { backgroundColor: 'black' },
            }}
            onClick={() => navigate(`/contactus`)}
          >
            Contact Us
          </Button>
          </Typography>
          <Typography variant="body2" align="justify" sx={{ color: "white", margin:"30px" }}>
            <div style={{width:"80vw"}}></div>
          </Typography>
        </Box>
      </Stack>
      {/* Copyright */}
      <Typography variant="body2" align="center" sx={{ color: "white", marginTop: 2 }}>
        &copy; {new Date().getFullYear()} The Camera Tales. All rights reserved.
      </Typography>
    </Paper>
  );
};

export default Footer;
