import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const PersonDetails = () => {
  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
  const baseURL = `${apiBaseURL}/uploads/`;
  const [categorizedArtists, setCategorizedArtists] = useState([]);
  const [moreArtists, setMoreArtists] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [visibleArtists, setVisibleArtists] = useState(24);

  

  useEffect(() => {
    const fetchArtists = async () => {
      try {
        const response = await axios.get(`${apiBaseURL}/api/admin/superusers`);
        const artists = response.data;

        const categorized = artists.filter(artist => artist.categories.length > 0);
        const uncategorized = artists.filter(artist => artist.categories.length === 0);

        setCategorizedArtists(categorized);
        setMoreArtists(uncategorized);
      } catch (error) {
        console.error('Error fetching artists:', error);
      }
    };

    fetchArtists();
  }, []);

  const categories = ['All', 'Actor', 'Actress', 'Producer', 'Director', 'Writer', 'Cinematographer', 'Executive Producer', 'Line Producer', 'Make Up', 'Costume Designer'];

  const filteredArtists = selectedCategory === 'All'
    ? categorizedArtists
    : categorizedArtists.filter(artist => artist.categories.includes(selectedCategory));

  const loadMoreArtists = () => {
    setVisibleArtists(prev => prev + 16);
  };

  return (
    <Box sx={{ padding: 3, maxWidth: 1200, margin: '0 auto', mt: '10vh' }}>
      {/* Category Filter Section */}
      <Grid container spacing={2} justifyContent="center" mb={4}>
        {categories.map(category => (
          <Grid item key={category}>
            <Button
              variant={selectedCategory === category ? 'contained' : 'outlined'}
              onClick={() => {
                setSelectedCategory(category);
                setVisibleArtists(24);  // Reset the visible artists count when a new category is selected
              }}
              sx={{
                color: 'white', // Text color
                borderColor: 'white', // Outline color
                '&:hover': {
                  borderColor: 'white', // Keep the outline white on hover
                  backgroundColor: selectedCategory === category ? 'primary.main' : 'transparent',
                },
            }}
            >
              {category}
            </Button>
          </Grid>
        ))}
      </Grid>

      {/* Artist Sections */}
      <Box textAlign="center">
        {/* Filtered Artists Section */}
        <Box mb={5}>
          <Typography variant="h4" textAlign="left" color={'white'} mb={3}>
            {selectedCategory}
          </Typography>
          <Grid container spacing={3}>
            {filteredArtists.slice(0, visibleArtists).map(artist => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={artist._id}>
                <ArtistCard artist={artist} baseURL={baseURL} />
              </Grid>
            ))}
          </Grid>
          {visibleArtists < filteredArtists.length && (
            <Button variant="contained" sx={{ mt: 3 }} onClick={loadMoreArtists}>
              Load More
            </Button>
          )}
        </Box>

        {/* More Artists Section (only show when 'All' is selected) */}
        {selectedCategory === 'All' && moreArtists.length > 0 && (
          <Box mb={5}>
            <Typography variant="h4" textAlign="left" mb={3}>More Artists</Typography>
            <Grid container spacing={3}>
              {moreArtists.slice(0, visibleArtists).map(artist => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={artist._id}>
                  <ArtistCard artist={artist} baseURL={baseURL} />
                </Grid>
              ))}
            </Grid>
            {visibleArtists < moreArtists.length && (
              <Button variant="contained" sx={{ mt: 3 }} onClick={loadMoreArtists}>
                Load More
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const ArtistCard = ({ artist, baseURL }) => {
  const navigate = useNavigate(); // Use useNavigate hook

  const handleCardClick = () => {
    navigate(`/cast/${artist._id}`); // Navigate to the artist's profile page
  };

  return (
    <Box
      onClick={handleCardClick} // Handle card click
      sx={{
        textAlign: 'center',
        padding: 2,
        borderRadius: 2,
        boxShadow: 3,
        transition: 'transform 0.3s, box-shadow 0.3s',
        cursor: 'pointer',
        backgroundColor: 'background.paper',
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: 5,
        },
      }}
    >
      <img
        src={artist.profilePicture ? `${baseURL}${artist.profilePicture.replace(/\\/g, '/')}` : 'https://via.placeholder.com/150?text=No+Image'}
        alt={`${artist.firstName} ${artist.lastName}`}
        style={{ width: '100%', height: 'auto', borderRadius: '50%', marginBottom: '15px' }}
      />
      <Typography variant="h6">{`${artist.firstName} ${artist.lastName}`}</Typography>
      <Typography variant="h6">{`${artist.categories}`}</Typography>
    </Box>
  );
};


export default PersonDetails;
