import React, { useEffect, useState } from 'react';
import CmsNavbar from './CmsNavbar';

const CmsHomeuser = () => {
  const [welcomeText, setWelcomeText] = useState('');
  const text = 'Welcome to Your CMS';

  useEffect(() => {
    let index = 0;
    const timer = setInterval(() => {
      setWelcomeText(text.slice(0, index));
      index++;
      if (index > text.length) clearInterval(timer);
    }, 100);
  }, []);

  const styles = {
    homepage: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: 'black',
      textAlign: 'center',
    },
    blastContainer: {
      position: 'relative',
      padding: '2rem',
      background: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '15px',
      backdropFilter: 'blur(10px)',
      boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
    },
    blastText: {
      fontSize: '2.5rem',
      fontWeight: 'bold',
      color: '#fff',
      textTransform: 'uppercase',
      animation: 'fadeIn 2s ease-in-out forwards',
    },
    subtext: {
      fontSize: '1.2rem',
      color: '#f7f7f7',
      marginTop: '0.5rem',
    },
    ctaButton: {
      marginTop: '2rem',
      padding: '0.8rem 2rem',
      backgroundColor: '#ff6f61',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      fontSize: '1.1rem',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease',
    },
    ctaButtonHover: {
      backgroundColor: '#e85b54',
    },
  };

  return (
    <>
      <CmsNavbar />
      <div style={styles.homepage}>
        <div style={styles.blastContainer}>
          <h1 style={styles.blastText}>{welcomeText}</h1>
          <p style={styles.subtext}>Manage media, users, and more with ease.</p>
          <button
            style={styles.ctaButton}
            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = styles.ctaButtonHover.backgroundColor)}
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = styles.ctaButton.backgroundColor)}
          >
            Get Started
          </button>
        </div>
      </div>
    </>
  );
};

export default CmsHomeuser;
