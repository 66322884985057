import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPersonalInfo,
  // addAllEducation,
  addAllExperience,
  addNewSkills,
} from '../../redux/features/combineReducer';

const SubmitButton = () => {
  const dispatch = useDispatch();
  const personalInfo = useSelector((state) => state.personalInfo);
  const educationDetails = useSelector((state) => state.educationDetails);
  const workExperience = useSelector((state) => state.workExperience);
  const keySkills = useSelector((state) => state.keySkills);

  const handleSubmit = async () => {
    try {
      // Dispatch actions to update Redux state
      dispatch(addPersonalInfo(personalInfo));
      // dispatch(addAllEducation(educationDetails.educations));
      dispatch(addAllExperience(workExperience.experiences));
      dispatch(addNewSkills(keySkills.skills));

      // Wait for Redux actions to complete before proceeding with API call
      const apiUrl = "http://82.112.235.153:5000/api/userDetails/userDetails"; // Update with your actual endpoint

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          personalInfo,
          educationDetails,
          workExperience,
          keySkills,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to save data");
      }

      console.log("Data saved successfully!");
      // Optionally, dispatch an action if needed after successful API call
    } catch (error) {
      console.error("Error saving data:", error);
      // Handle error cases, show an error message or dispatch an action
    }
  };

  return (
    <button onClick={handleSubmit}>Submit</button>
  );
};

export default SubmitButton;
