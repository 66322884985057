import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Typography, Box, MenuItem, FormControl, Select, InputLabel } from '@mui/material';
import CmsNavbar from './CmsNavbar';

const AddMediaToUser = () => {
  const [mediaType, setMediaType] = useState('movie'); // Default to 'movie'
  const [movieId, setMovieId] = useState('');
  const [mediaItems, setMediaItems] = useState([]);
  const [message, setMessage] = useState('');

  // Get the current user from localStorage
  const currentUser = JSON.parse(localStorage.getItem('user')); // Assuming the user object is stored as 'user'
  const currentUserId = currentUser?.id; // Get the user ID from localStorage

  // Fetch media items based on selected media type
  useEffect(() => {
    const fetchMediaItems = async () => {
      try {
        const mediaRes = await axios.get(`https://backend.thecameratales.com/api/${mediaType}`); // Dynamically fetch based on mediaType
        setMediaItems(mediaRes.data);
      } catch (error) {
        console.error(`Error fetching ${mediaType}:`, error);
        setMessage(`Failed to load ${mediaType}`);
      }
    };

    fetchMediaItems();
  }, [mediaType]); // Re-fetch when mediaType changes

  // Handle form submission to add the selected media item to the user's list
  const handleAddMedia = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`https://backend.thecameratales.com/api/${mediaType}/${currentUserId}/movies/${movieId}`);
      setMessage(response.data.message); // Success message
    } catch (error) {
      console.error(`Error adding ${mediaType} to user:`, error);
      setMessage(`Error adding ${mediaType}`);
    }
  };

  return (
    <>
    <CmsNavbar/>
    <Box sx={{ maxWidth: 500, margin: 'auto', padding: 2, marginTop:'25vh', backgroundColor:'white' }}>
      <Typography variant="h4" gutterBottom>
        Add Media to Your List
      </Typography>

      {/* Media Type Select Dropdown */}
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel id="media-type-label">Select Media Type</InputLabel>
        <Select
          labelId="media-type-label"
          value={mediaType}
          label="Select Media Type"
          onChange={(e) => setMediaType(e.target.value)} // Change the API type dynamically
        >
          <MenuItem value="movie">Movie</MenuItem>
          <MenuItem value="webseries">Web Series</MenuItem>
          <MenuItem value="webshows">Web Shows</MenuItem>
          <MenuItem value="webshows">Web Shows</MenuItem>
          <MenuItem value="upcoming">Upcoming</MenuItem>
          <MenuItem value="tales">Tales</MenuItem>
          <MenuItem value="toptalesthisweek">Top Tales</MenuItem>
          <MenuItem value="musicvideos">Music Videos</MenuItem>
          <MenuItem value="shows">Shows</MenuItem>
          <MenuItem value="shortfilms">Short Films</MenuItem>
          <MenuItem value="fridaytalkies">Friday Talkies</MenuItem>
        </Select>
      </FormControl>

      {/* Media Item Select Dropdown */}
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel id="media-item-label">Select Media</InputLabel>
        <Select
          labelId="media-item-label"
          value={movieId}
          label="Select Media"
          onChange={(e) => setMovieId(e.target.value)} // Select media item ID
        >
          {mediaItems.map((item) => (
            <MenuItem key={item._id} value={item._id}>
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Submit Button */}
      <Button variant="contained" color="primary" fullWidth onClick={handleAddMedia}>
        Add {mediaType.charAt(0).toUpperCase() + mediaType.slice(1)} to Your List
      </Button>

      {/* Message Display */}
      {message && (
        <Typography variant="body1" color={message.includes('Error') ? 'error' : 'success'} sx={{ marginTop: 2 }}>
          {message}
        </Typography>
      )}
    </Box>
    </>
  );
};

export default AddMediaToUser;
