import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAiAU9zjSV3rHi5jJXX_HX7dHQZ5I16p8I",
  authDomain: "the-camera-tales.firebaseapp.com",
  projectId: "the-camera-tales",
  storageBucket: "the-camera-tales.appspot.com",
  messagingSenderId: "450705986425",
  appId: "1:450705986425:web:ff5a345b7a3ca73eebd5a8",
  measurementId: "G-VKM0F9HWP2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
console.log("Firebase initialized:", app);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db}; // Export the auth object

