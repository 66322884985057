import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';

const ImageCropper = ({ imageFile, onCropComplete, aspectRatio = 3 / 4, canvasWidth = 4500, canvasHeight = 6000 }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedImageBlob, setCroppedImageBlob] = useState(null);

    // Update crop position and zoom
    const onCropChange = (newCrop) => setCrop(newCrop);
    const onZoomChange = (newZoom) => setZoom(newZoom);

    // Create the cropped image using canvas and return the Blob
    const createCroppedImage = async (croppedAreaPixels) => {
        const canvas = document.createElement("canvas");
        canvas.width = canvasWidth; // Use dynamic width
        canvas.height = canvasHeight; // Use dynamic height
        const ctx = canvas.getContext("2d");

        const image = new Image();
        image.src = URL.createObjectURL(imageFile);
        await new Promise((resolve) => (image.onload = resolve));

        // Draw the cropped image on the canvas
        ctx.drawImage(
            image,
            croppedAreaPixels.x,
            croppedAreaPixels.y,
            croppedAreaPixels.width,
            croppedAreaPixels.height,
            0,
            0,
            canvas.width,
            canvas.height
        );

        // Return the Blob of the cropped image
        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                resolve(blob);
            }, "image/jpeg");
        });
    };

    // Handle crop completion (but don't finalize until user confirms)
    const onCropFinished = useCallback(async (croppedArea, croppedAreaPixels) => {
        const croppedImageBlob = await createCroppedImage(croppedAreaPixels);
        setCroppedImageBlob(croppedImageBlob);
    }, [canvasWidth, canvasHeight]);

    // Confirm the crop and send the result to parent
    const handleConfirmCrop = () => {
        if (croppedImageBlob) {
            onCropComplete(croppedImageBlob);
        }
    };

    return (
        <div style={{ position: 'relative', height: 400, width: '100%' }}>
            {/* The Cropper */}
            <Cropper
                image={URL.createObjectURL(imageFile)}
                crop={crop}
                zoom={zoom}
                aspect={aspectRatio} // Use dynamic aspect ratio
                onCropChange={onCropChange}
                onZoomChange={onZoomChange}
                onCropComplete={onCropFinished}
            />

            {/* The Confirm Crop Button */}
            <div style={{
                position: 'absolute',
                bottom: '10px',
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 10,
            }}>
                <button onClick={handleConfirmCrop} style={{ backgroundColor: 'green', color: 'white', padding: '5px', border: '2px solid black' }}>Confirm Crop</button>
            </div>
        </div>
    );
};

export default ImageCropper;
