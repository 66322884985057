import { useSelector, useDispatch } from "react-redux";
import LoginIcon from "@mui/icons-material/Login";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  useScrollTrigger,
  useMediaQuery,
  useTheme,
  Avatar,  // Import Avatar for displaying profile pictures
} from "@mui/material";
import { cloneElement, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";  // Import axios to make API calls
import menuConfigs from "../../configs/menu.configs";
import { setThemeMode } from "../../redux/features/themeModeSlice";
import Logo from "./Logo";
import themeModes from "../../configs/theme.configs";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

const ScrollAppBar = ({ children, window }) => {
  const { themeMode } = useSelector((state) => state.themeMode);

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
    target: window ? window() : undefined,
  });

  return cloneElement(children, {
    sx: {
      color: "white",
      backgroundColor: "black",
      opacity: 0.9,
      borderBottom: "1px solid white",
    },
  });
};

const Topbar = ({ toggleSidebar }) => {
  const { appState } = useSelector((state) => state.appState);
  const { themeMode } = useSelector((state) => state.themeMode);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [user, setUser] = useState(null);  // Store the user object with profile data
  const [profilePicture, setProfilePicture] = useState("");  // Store the profile picture URL

  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md')); // Check if the screen size is small
  const navigate = useNavigate(); // For internal navigation

  const onSwithTheme = () => {
    const theme =
      themeMode === themeModes.dark ? themeModes.light : themeModes.dark;
    dispatch(setThemeMode(theme));
  };

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");  // Get the token from localStorage

    if (storedUser && storedUser.id && token) {
      setIsAuthenticated(true);
      setUser(storedUser);  // Set the entire user object

      // Fetch profile picture from the server
      axios.get(`https://backend.thecameratales.com/api/admin/loggedinusers/${storedUser.id}`, {
        headers: { 'x-auth-token': token }  // Pass the token in the request header
      })
        .then((response) => {
          console.log("Fetched user data:", response.data);  // Log the entire fetched data
          setProfilePicture(response.data.profilePicture);  // Update the profile picture state
        })
        .catch((error) => {
          console.error("Error fetching profile picture:", error);
        });
    }
  }, []);

  const handleLogout = () => {
    // Remove user and token from localStorage
    localStorage.removeItem("user");
    localStorage.removeItem("token");
  
    // Update authentication state
    setIsAuthenticated(false);
  
    // Redirect to login page
    window.location.href = "/login";
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = (event) => {
    if (!isAuthenticated) {
      navigate("/login"); // Redirect unauthenticated users to the login page
      return;
    }
    if (isSmallScreen) {
      handleMenuOpen(event); // Open the menu on small screens
    } else {
      handleProfileRedirect(); // Directly redirect on larger screens
    }
  };

  const handleProfileRedirect = () => {
    if (user) {
      if (user.role === "super-user") {
        window.location.href = `/users/${user.id}`;
      } else {
        window.location.href = "/myprofile";
      }
    }
  };

  const handleExternalLink = (url) => {
    window.location.href = url; // Handle external link redirection
  };

  const handleLogoClick = () => {
    navigate("/"); // Navigate to homepage
  };

  return (
    <>
      <ScrollAppBar>
        <AppBar elevation={0} sx={{ zIndex: 9999 }}>
          <Toolbar
            sx={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <IconButton
              color="inherit"
              sx={{
                ml: {
                  xs: -1,
                  sm: -2,
                  md: -2,
                  lg: -2,
                },
              }}
              onClick={toggleSidebar}
            >
              <MenuIcon />
            </IconButton>

            <div onClick={handleLogoClick} style={{ cursor: 'pointer' }}> {/* Make logo clickable */}
              <Logo />
            </div>

            <Box
              flexGrow={1}
              alignItems="center"
              display="flex"
              justifyContent="center"
            >
              <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
                {menuConfigs.main.map((item, index) => (
                  <Button
                    key={index}
                    sx={{
                      mb: -2,
                      mr: 8,
                      fontWeight: "bolder",
                      color: "white",
                      display: { xs: "none", md: "block" },
                      justifyContent: "center",
                    }}
                    onClick={() =>
                      item.isExternal
                        ? handleExternalLink(item.path)
                        : navigate(item.path)
                    }
                  >
                    {item.display}
                  </Button>
                ))}
              </Box>

              <IconButton color="inherit" component={Link} to="/search">
                <SearchIcon />
              </IconButton>
            </Box>

            {/* Profile, Notifications, and Logout/Login Icons */}
            <Box sx={{ display: { xs: "flex", md: "flex" }, alignItems: "center" }}>
              <IconButton color="inherit" onClick={handleProfileClick}>
                {user && profilePicture ? (  // Use the profilePicture state that holds the fetched data
                  <>
                    {console.log("Profile Picture URL:", `https://backend.thecameratales.com/uploads/${profilePicture}`)}  {/* Log the full profile picture URL */}
                    <Avatar src={`https://backend.thecameratales.com/uploads/${profilePicture}`} />  {/* Display the profile picture if available */}
                  </>
                ) : (
                  <>
                    {console.log("No profile picture, displaying icon.")}  {/* Log if no profile picture */}
                    <AccountCircleIcon />  {/* Otherwise, show the icon */}
                  </>
                )}
              </IconButton>

              <IconButton color="inherit" sx={{ display: { xs: "none", md: "flex" } }}>
                <NotificationsIcon />
              </IconButton>

              {isAuthenticated ? (
                <IconButton color="inherit" onClick={handleLogout} sx={{ display: { xs: "none", md: "flex" } }}>
                  <ExitToAppIcon />
                </IconButton>
              ) : (
                <Button color="inherit" component={Link} to="/login">
                  Login
                </Button>
              )}

              {/* Menu for small screens */}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{ display: { xs: "block", md: "none" } }}
              >
                <MenuItem onClick={handleProfileRedirect}>
                  <IconButton color="inherit">
                    <AccountCircleIcon />
                  </IconButton>
                  My Profile
                </MenuItem>
                <MenuItem onClick={handleMenuClose}>
                  <IconButton color="inherit">
                    <NotificationsIcon />
                  </IconButton>
                  Notifications
                </MenuItem>
                {isAuthenticated ? (
                  <MenuItem onClick={handleLogout}>
                    <IconButton color="inherit">
                      <ExitToAppIcon />
                    </IconButton>
                    Logout
                  </MenuItem>
                ) : (
                  <MenuItem onClick={handleMenuClose} component={Link} to="/login">
                    <IconButton color="inherit">
                      <LoginIcon />
                    </IconButton>
                    Login
                  </MenuItem>
                )}
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
      </ScrollAppBar>
    </>
  );
};

export default Topbar;
