import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CancelIcon from '@mui/icons-material/Cancel';
// import * as React from 'react';
import React, { useState } from 'react'; // Importing useState only once
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
// import logo from './assets/logo.jpg';
import { toast } from 'react-toastify';
import ConfettiExplosion from 'react-confetti-explosion';
import axios from 'axios'; // Import axios for HTTP requests
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { auth } from '../components/common/firebase/firebaseconfig'
import { CenterFocusStrong } from '@mui/icons-material';
// import GenreDropdown from './genre';




const { v4: uuidv4 } = require('uuid');

const Details = () => {
  const [formData, setFormData] = React.useState({
    name: '',
    dateofestablishment: null,
    address: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    phone: '',
    email: '',
    instaId: '',
    fbId: '',
    producer: [{ producername: '', picture: '', produceremail: '' }],
    coproducer: [{ coproducername: '', picture: '', coproduceremail: '' }],
    execuetiveproducer: '',
    productionmanager: '',
    yearsTraining: null,
    actor: [{ actorname: '', picture: '', actoremail: '' }],
    writer: [{ writername: '', picture: '', writeremail: '' }],
    director: [{ directorname: '', picture: '', directoremail: '' }],
    assistantdirector: [{ assistantdirectorname: '', picture: '', assistantdirectoremail: '' }],
    associatedirector: [{ associatedirectorname: '', picture: '', associatedirectoremail: '' }],
    actiondirector: '',
    dopcameraman: '',
    colourist: '',
    vfxartist: '',
    editor: '',
    lyrics: '',
    musicdirector: '',
    singers: '',
    composer: '',
    choreographer: '',
    artdirector: '',
    makeupartist: '',
    costumedesigner: '',
    editingstudio: '',
    musicstudio: '',
    dubbingstudio: '',
    vfxstudio: '',
    movieVideo: null, // Add movieVideo field
    webseriesVideo: null,
    webshowVideo: null,
    musicVideo: null,
    shortfilmVideo: null,
    realityshowVideo: null,
    podcastVideo: null,
    otp: '',
    isVerified: true
  });
  const [selectedFileType, setSelectedFileType] = React.useState('');
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [declarationChecked, setDeclarationChecked] = React.useState(false);
  const [formWarning, setFormWarning] = React.useState('');
  const [uploadButtonText, setUploadButtonText] = React.useState('Upload File');
  const [showModal, setShowModal] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [captchaChecked, setCaptchaChecked] = useState(false);
  const [otpData, setOtpData] = useState({
    otp: "",
    isOtpSent: false,
    isOtpVerified: false,
  });
  const [error, setError] = useState("");
  const [user, setUser] = useState(null);
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
//   const [selectedGenres, setSelectedGenres] = useState([]); // Define selectedGenres state

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;


  const handleOtpChange = ({ target }) => {
    setOtpData({ ...otpData, [target.name]: target.value });
  };

  const handleSendOtp = async () => {
    try {
      const url = `${apiBaseURL}/v1/api/send-otp`;
      const { data: res } = await axios.post(url, { email: formData.email });
      console.log(res.message);
      setOtpData({ ...otpData, isOtpSent: true });
      console.log(otpData.isOtpSent)
      console.log(otpData.isOtpVerified)
    } catch (error) {
      setError("Error sending OTP. Please try again.");
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const url = `${apiBaseURL}/v1/api/verify-otp`;
      const { data: res } = await axios.post(url, {
        email: formData.email,
        otp: otpData.otp,
      });
      console.log(res.message);
      if (res.message === "OTP verified successfully") {
        setOtpData({ ...otpData, isOtpVerified: true });
      } else {
        setError("Error verifying OTP. Please try again.");
      }
    } catch (error) {
      setError("Error verifying OTP. Please try again.");
    }
  };

  const [phone, setPhone] = useState('');

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phone: "+" + value });
  };

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileId = generateUniqueId() + '.' + getFileExtension(file.name); // Generate a unique identifier for the file with extension // Generate a unique identifier for the file
      setSelectedFile({ fileId, file }); // Store the file object along with its unique identifier
      setFormData({
        ...formData,
        [`${selectedFileType}Video`]: fileId, // Store the file identifier in the form data
      });
      // setUploadButtonDisabled(true);
    }
  };


  const sendOtp = async () => {
    try {
      const recaptcha = new RecaptchaVerifier(auth, "recaptcha", {})

      console.log("auth:", auth);
      console.log("recaptcha:", document.getElementById("recaptcha"));
      const confirmation = await signInWithPhoneNumber(auth, formData.phone, recaptcha)
      setUser(confirmation);
      setOtpSent(true); // OTP sent, enable Verify button
      // Hide reCAPTCHA element
      document.querySelector('#recaptcha iframe').style.display = 'none';
    } catch (err) {
      console.error(err)
    }
  }

  const verifymobileOtp = async () => {
    try {
      const data = await user.confirm(otp)
      console.log(data)
      // user.confirm(otp)
    }
    catch (err) {
      console.error(err);
    }

  }

  const handleCaptchaCheck = () => {
    setCaptchaChecked(true); // CAPTCHA checked, hide it
  };

  const handleUpload = async () => {
    if (selectedFile && selectedFileType) {
      try {
        const formDataUpload = new FormData();
        formDataUpload.append(`${selectedFileType}Video`, selectedFile.file, selectedFile.fileId); // Append the file with its identifier

        const response = await fetch(`${apiBaseURL}/v1/api/upload-${selectedFileType}video`, {
          method: 'POST',
          body: formDataUpload,
        });

        if (!response.ok) {
          throw new Error('Failed to upload file');
        }

        console.log('File uploaded successfully');
        setSelectedFile(null);
        setFormData({
          ...formData,
          [`${selectedFileType}Video`]: selectedFile.fileId, // Update the form data with the file identifier
        });
        setUploadButtonText('Uploaded Successfully');
        // Disable file input after successful upload
        document.getElementById(`upload-${selectedFileType}video`).disabled = true;
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };
  // Function to get the file extension from the file name
  const getFileExtension = (filename) => {
    return filename.split('.').pop();
  };

  const generateUniqueId = () => {
    return uuidv4();
  };


  const handleRemoveFile = () => {
    setSelectedFile(null);
    setFormData({
      ...formData,
      [`${selectedFileType}Video`]: null, // Remove selected file from movieVideo field
    });
    // Clear the input field value to allow choosing the same file again
    document.getElementById(`upload-${selectedFileType}video`).value = '';
    setUploadButtonText('Upload File');
  };


 
  const handleSubmit = async () => {
    try {

      // Email validation
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (formData.email) {
        if (!formData.email || !emailPattern.test(formData.email)) {
          setFormWarning('Please enter valid email');
          return;
        }
        // Check if email domain is from a reputed organization
        const reputedDomains = ['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com']; // Add reputed domain names here
        const emailDomain = formData.email.split('@')[1];
        if (!reputedDomains.includes(emailDomain)) {
          setFormWarning('Please enter email from gmail, yahoo, hotmail or outlook');
          return;
        }
        if (!otpData.isOtpSent || !otpData.isOtpVerified) {
          setFormWarning("Please send and verify the OTP.");
          console.log(otpData.isOtpSent)
          console.log(otpData.isOtpVerified)
          return;
        }
      }


      // Date of Birth validation
      //  if (!formData.dob) {
      //    throw new Error('Please enter your date of birth.');
      //  }
      const response = await fetch(`${apiBaseURL}/v1/api/form`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          declaration: declarationChecked, // Include the declaration field in the form data
        //   genres: selectedGenres,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        errorData.errors.forEach(error => {
          toast.error(error.msg);
        });
        throw new Error('Failed to submit form');
      }



      setFormWarning(<h1 align="center" style={{ color: 'green' }}>Form Submitted Successfully</h1>);
      setShowModal(true);
      setShowConfetti(true);
      // Clear form data after successful submission
      setFormData({
        name: '',
        dateofestablishment: null,
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        phone: '',
        email: '',
        instaId: '',
        fbId: '',
        coproducer: [{ coproducername: '', picture: '', coproduceremail: '' }],
        execuetiveproducer: '',
        productionmanager: '',
        yearsTraining: null,
        actor: [{ actorname: '', picture: '', actoremail: '' }],
        writer: [{ writername: '', picture: '', writeremail: '' }],
        producer: [{ producername: '', picture: '', produceremail: '' }],
        director: [{ directorname: '', picture: '', directoremail: '' }],
        assistantdirector: [{ assistantdirectorname: '', picture: '', assistantdirectoremail: '' }],
        associatedirector: [{ associatedirectorname: '', picture: '', associatedirectoremail: '' }],
        actiondirector: '',
        dopcameraman: '',
        colourist: '',
        vfxartist: '',
        editor: '',
        lyrics: '',
        musicdirector: '',
        singers: '',
        composer: '',
        choreographer: '',
        artdirector: '',
        makeupartist: '',
        costumedesigner: '',
        editingstudio: '',
        musicstudio: '',
        dubbingstudio: '',
        vfxstudio: '',
        movieVideo: null, // Add movieVideo field
        webseriesVideo: null,
        webshowVideo: null,
        musicVideo: null,
        shortfilmVideo: null,
        realityshowVideo: null,
        podcastVideo: null,
        otp: '',
        isVerified: true
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      setFormWarning('Failed to submit form. Please try again. Please fill all the fields marked mandatory *');
    }
  };

  // Function to handle modal close
  const handleCloseModal = () => {
    // Hide the modal
    setShowModal(false);
    // Reload the page
    window.location.reload();
  };


  return (
    <>
      {/* Logo and Company Name */}
      <div style={{ textAlign: 'center', marginBottom: '20px', backgroundColor: 'black' }}>
        {/* <img src={logo} alt="The Camera Tales" style={{ width: '50%', height: '100px' }} /> */}
        {/* <Typography variant="h5">The Camera Tales</Typography> */}
      </div>
      <Container style={{backgroundColor:'whitesmoke', marginTop:'20vh', marginBottom:'20vh'}}>
        <Box>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <Typography
                variant="h3"
                align="center"
                sx={{
                  fontFamily: 'Roboto',   // Choose your desired font family
                  fontWeight: 700,        // Adjust the font weight for emphasis
                  fontSize: '3rem',       // Adjust the font size as needed
                  color: '#333',          // Set the text color
                  marginTop: '2rem',      // Add margin top for spacing
                  marginBottom: '2rem',   // Add margin bottom for spacing
                  // Add any other custom styles here
                }}
              >
                Content Registration Form
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Name" variant="outlined" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} required />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  fullWidth
                  label="Date of Establishment"
                  inputVariant="outlined"
                  value={formData.dateofestablishment} // Pass the value from your form data state
                  sx={{ '& input': { padding: '2vh 12vw' } }} // Adjust the padding to increase the size
                  onChange={(date) => setFormData({ ...formData, dateofestablishment: date })} // Update the form data state when the date changes
                  renderInput={(params) => <TextField {...params} />}
                  required
                />
              </LocalizationProvider> */}

            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Address" variant="outlined" value={formData.address} onChange={(e) => setFormData({ ...formData, address: e.target.value })} required />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="City" variant="outlined" value={formData.city} onChange={(e) => setFormData({ ...formData, city: e.target.value })} required />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="State/Province" variant="outlined" value={formData.state} onChange={(e) => setFormData({ ...formData, state: e.target.value })} required />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Zip/Postal Code" variant="outlined" value={formData.zip} onChange={(e) => setFormData({ ...formData, zip: e.target.value })} required />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Country" variant="outlined" value={formData.country} onChange={(e) => setFormData({ ...formData, country: e.target.value })} required />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Phone no" variant="outlined" value={formData.phone} onChange={(e) => setFormData({ ...formData, phone: e.target.value })} required />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <PhoneInput
                    fullWidth
                    label="Phone no"
                    variant="contained"
                    country={'us'}
                    value={formData.phone}
                    onChange={handlePhoneChange}
                    required
                    // placeholder="enter mobile number"
                    // placeholder={enter}
                    inputClass="custom-input-class"
                    inputStyle={{
                      marginLeft: '40px',
                      width: '93%',
                      border: '2px solid #ccc', // Add border styles
                      borderRadius: '5px', // Add border radius
                      padding: '10px', // Add padding
                      fontSize: '16px', // Adjust font size
                      placeholder: "Enter your phone number"
                      // Add any other custom input styles
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" fullWidth color="primary" onClick={sendOtp}>Send mobile OTP</Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2} justifyContent="center"> {/* Centering the grid container */}
              <Grid item xs={12}>
                <div id='recaptcha' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px' }}></div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}> {/* Centering the OTP input and button */}
                  <TextField onChange={(e) => setOtp(e.target.value)} variant='outlined' size='small' label="Enter mobile OTP" fullWidth />
                  <Button onClick={verifymobileOtp} variant='contained' fullWidth color='success' style={{ marginTop: '16px' }} disabled={!otpSent}>Verify mobile OTP</Button>
                </div>
              </Grid>
            </Grid>

            {/* </Grid> */}

            {/* </Grid> */}


            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Insta Id" variant="outlined" value={formData.instaId} onChange={(e) => setFormData({ ...formData, instaId: e.target.value })} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="FB Id " variant="outlined" value={formData.fbId} onChange={(e) => setFormData({ ...formData, fbId: e.target.value })} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Email Address" variant="outlined" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} required />
            </Grid>
            <Grid item xs={12} sm={6}>
              {!otpData.isOtpSent ? (
                <Button variant="contained" color="primary" onClick={handleSendOtp}>Send OTP</Button>
              ) : (
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <TextField
                      type="text"
                      placeholder="Enter OTP"
                      name="otp"
                      onChange={handleOtpChange}
                      value={otpData.otp}
                      variant="outlined"
                      fullWidth
                    // size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button variant="contained" color="primary" fullWidth onClick={handleVerifyOtp} style={{ height: '55px' }} >Verify OTP</Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
            {/* here */}
            <Grid item xs={12}>
              <InputLabel htmlFor="upload-file-type">Select File Type</InputLabel>
              <Select
                id="upload-file-type"
                value={selectedFileType}
                onChange={(event) => setSelectedFileType(event.target.value)}
                fullWidth
                displayEmpty
              >
                <MenuItem value=""><Typography variant="body2" style={{ fontStyle: 'italic', fontSize: '15px', color: 'gray' }}>Please Select The Content Category</Typography></MenuItem>
                <MenuItem value="dance">Dance</MenuItem>
              </Select>
            </Grid>
            {selectedFileType && (
              <Grid item xs={12}>
                <InputLabel htmlFor={`upload-${selectedFileType}-video`}>Upload {selectedFileType.charAt(0).toUpperCase() + selectedFileType.slice(1)} Video: *</InputLabel>
                <Typography variant="body2" color="error" style={{ marginBottom: '8px' }}>This field is mandatory.</Typography>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                  <input
                    accept="video/*"
                    style={{ display: 'none' }}
                    id={`upload-${selectedFileType}video`}
                    multiple
                    type="file"
                    onChange={handleFileChange}
                    required
                  />
                  <label htmlFor={`upload-${selectedFileType}video`}>
                    <Button variant="contained" component="span" color="primary" startIcon={<CloudUploadIcon />}>
                      {uploadButtonText}

                    </Button>
                  </label>
                  {selectedFile && (
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: 16 }}>
                      <Typography variant="body1">
                        Selected File: {selectedFile.file.name}
                      </Typography>
                      <Button onClick={handleRemoveFile} style={{ marginLeft: 8, color: 'red' }}><CancelIcon /></Button>
                    </div>
                  )}
                </div>
                <Button variant="contained" color="success" fullWidth onClick={handleUpload} disabled={!selectedFile}>
                  Upload File
                </Button>
              </Grid>
            )}
                        {/* Add other form fields here */}
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox checked={declarationChecked}
                  onChange={(e) => setDeclarationChecked(e.target.checked)} required />}
                label="Declaration: By signing below, I confirm that the information provided in this audition form is accurate and complete to the best of my knowledge. I understand that any false information may result in disqualification from the audition process."
              />
            </Grid>



            <Grid item xs={12}>
              <Button style={{marginBottom:'5vh'}} variant="contained" color="primary" fullWidth onClick={handleSubmit}>Submit</Button>
            </Grid>
          </Grid>

        </Box>
        <Modal
          open={showModal}
          onClose={handleCloseModal}
          aria-labelledby="thank-you-modal"
          aria-describedby="thank-you-message"
        >
          <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Box style={{ width: '300px', height: '300px', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h2 id="thank-you-modal" style={{ textAlign: 'center', marginBottom: '20px' }}>Thank You!</h2>
                <p id="thank-you-message" style={{ textAlign: 'center', fontSize: '16px' }}>Your form has been submitted successfully.</p>
                <div style={{ marginTop: '20px' }}>
                  <Button onClick={handleCloseModal} variant="contained" color="primary">
                    Close
                  </Button>
                </div>
              </div>
              {/* Render ConfettiExplosion component when showConfetti is true */}
              {showConfetti && <ConfettiExplosion
                width={1600}          // Adjust the width of the explosion area
                height={600}          // Adjust the height of the explosion area
                particleCount={250}   // Total number of particles used
                particleSize={12}     // Size of particles in pixels
                duration={2000}       // Duration of explosion in milliseconds
                colors={['#FFC700', '#FF0000', '#2E3191', '#41BBC7']} // Array of colors for particles
                force={0.5}           // Vertical force at which particles initially explode
              />}
            </Box>
          </Container>
        </Modal>

      </Container>
    </>
  );
}

export default Details;
