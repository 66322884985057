import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/material';
// import  image

// const AboutContainer = styled(Box)(({ theme }) => ({
//     display: "flex",
//     flexDirection: 'column',
//     alignItems: 'center',
//     padding: theme.spacing(4),
//     backgroundColor: '#f5f5f5',
//     borderRadius: '8px',
//     boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
//     textAlign: 'center',
//     margin: '20px',
//     maxWidth: '800px',
//     [theme.breakpoints.up('md')]: {
//         flexDirection: 'row',
//         padding: theme.spacing(6),
//     }

// }))

// const ImageContainer = styled(Box)(({ theme }) => ({
//     flex: 1,
//     backgroundImage: '',
//     backgroundSize: 'cover',
//     padding: theme.spacing(4),
//     backgroundColor: '#f5f5f5',
//     borderRadius: '8px',
//     boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
//     textAlign: 'center',
//     margin: '20px',
//     maxWidth: '800px',
//     [theme.breakpoints.up('md')]: {
//         flexDirection: 'row',
//         padding: theme.spacing(6),
//     }

// }))


const TextContainer = styled(Box)(({ theme }) => ({
    flex: 1,
    backgroundImage: '',
    backgroundSize: 'cover',
    padding: theme.spacing(4),
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    justifyContent:'center',
    marginTop: '30vh',
    marginBottom: '30vh',
    maxWidth: '100vw',
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        padding: theme.spacing(6),
    }

}))


const AboutUs = () => {
    return (
        <>
            {/* <AboutContainer /> */}
            {/* <ImageContainer /> */}
            <TextContainer>
                <Typography variant='h4' gutterBottom>
                    About Us
                </Typography>
                <Typography variant='body1' paragraph>
                    Welcome to The Camera Tales, your ultimate
                    destination for high-quality entertainment. We
                    are a cutting-edge Over-The-Top (OTT) service
                    committed to transforming the way you
                    experience media. Our platform offers a diverse
                    range of content, including movies, TV shows,
                    documentaries, and exclusive originals, all
                    tailored to meet the varied tastes of our
                    audience
                </Typography>
                {/* <Button variant='contained' color='primary' sx={{mt:2}}>
                    Learn More
                </Button> */}
            </TextContainer>
        </>
    )
}

export default AboutUs;

