import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import Footer from "../common/Footer";
import Topbar from "../common/Topbar";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import SideBarDiv from "../common/sideBarDiv";
import { useMediaQuery } from '@mui/material';

const MainLayout = ({ isOpen, toggle }) => {
  const isSmallScreen = useMediaQuery('(max-width: 600px)');
  const dispatch = useDispatch();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

 

  return (
    <div style={{ margin: 0, padding: 0, height: '100vh' }}>
      <Box display="flex" flexDirection="column" minHeight="100%" sx={{ overflowX: "hidden" }}>
        <Topbar toggleSidebar={toggleSidebar} />

        <Box display="flex" flexGrow={1}>
          {/* Sidebar */}
          <Box
            sx={{
              position: "fixed",
              zIndex: 100,
              display: isSidebarOpen || !isSmallScreen ? "block" : "none",
            }}
          >
            <SideBarDiv isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
          </Box>

          <Box component="main" flexGrow={1} overflow="hidden" sx={{
            marginLeft: isSmallScreen ? '-3vw' : '50px',
          }}>
            
              <Outlet />
           
          </Box>
        </Box>

        <Footer />
      </Box>
    </div>
  );
};

export default MainLayout;
