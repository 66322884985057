import { createSlice } from "@reduxjs/toolkit";

const initialWorkExperienceState = {
  experiences: [],
};

const workExperienceSlice = createSlice({
  name: "workExperience",
  initialState: initialWorkExperienceState,
  reducers: {
    addExperience(state, action) {
      state.experiences.push(action.payload);
    },
    addAllExperience(state, action) {
      state.experiences = action.payload;
    },
  },
});

export const { addExperience, addAllExperience } = workExperienceSlice.actions;
export const workExperienceReducer = workExperienceSlice.reducer;
