import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Button, Avatar, Box, Typography, CircularProgress, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText } from '@mui/material';
import Cropper from 'react-cropper';
import { Share } from '@mui/icons-material';
import 'cropperjs/dist/cropper.css';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomPhotoCollage from '../Component/PhotoCollage';
// import Video from '../Component-4/Video';
import FollowIcon from '@mui/icons-material/PersonAdd';
import UnfollowIcon from '@mui/icons-material/PersonRemove';
import UserProfile from '../Component-4/Video';

export default function ProfilePage({ loggedInUser, onUpdateProfilePicture }) {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [posts, setPosts] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [openCropperModal, setOpenCropperModal] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const cropperRef = useRef();
  const fileInputRef = useRef();
  const [isFollowing, setIsFollowing] = useState(false); // To track following status
  const userlocalId = JSON.parse(localStorage.getItem('user') || '{}').id; // Logged-in user's ID
  const token = localStorage.getItem('token');
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);

  // Modal state
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState('followers'); // 'followers' or 'following'


  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${apiBaseURL}/api/admin/users/${userId}`);
        setUser(response.data);
        // Check if the logged-in user is already following this cast
        const isUserFollowing = response.data.followers.includes(userlocalId);
        setIsFollowing(isUserFollowing);

        console.log(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const fetchPosts = async () => {
      try {
        const response = await axios.get(`${apiBaseURL}/api/admin/posts/user/${userId}`);
        setPosts(response.data);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchUserData();
    fetchPosts();
  }, [userId, userlocalId, token]);


  // Define fetchFollowersAndFollowing using useCallback
  const fetchFollowersAndFollowing = useCallback(async () => {
    try {
      const [followersRes, followingRes] = await Promise.all([
        axios.get(`${apiBaseURL}/api/users/${userId}/followers`, {
          headers: { 'x-auth-token': token },
        }),
        axios.get(`${apiBaseURL}/api/users/${userId}/following`, {
          headers: { 'x-auth-token': token },
        }),
      ]);

      setFollowers(followersRes.data.followers);
      setFollowing(followingRes.data.following);
    } catch (err) {
      console.error("Error fetching followers/following:", err);
    }
  }, [userId, token]);

  useEffect(() => {
    fetchFollowersAndFollowing();
  }, [fetchFollowersAndFollowing]);

  // Handle follow button click
  const handleFollow = async () => {
    try {
      await axios.put(
        `${apiBaseURL}/api/users/${userlocalId}/follow`,
        { targetUserId: userId },
        { headers: { 'x-auth-token': token } }
      );
      setIsFollowing(true); // Update UI after successful follow
      fetchFollowersAndFollowing()
    } catch (err) {
      console.error("Error following user:", err);
    }
  };

  // Handle unfollow button click
  const handleUnfollow = async () => {
    try {
      await axios.put(
        `${apiBaseURL}/api/users/${userlocalId}/unfollow`,
        { targetUserId: userId },
        { headers: { 'x-auth-token': token } }
      );
      setIsFollowing(false); // Update UI after successful unfollow
      fetchFollowersAndFollowing()
    } catch (err) {
      console.error("Error unfollowing user:", err);
    }
  };


  // File change handler
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setPreview(URL.createObjectURL(file));
    setUploadSuccess(false);
    setOpenCropperModal(true); // Open cropper modal after selecting a file
  };


  const handleShareProfile = () => {
    const profileUrl = `${window.location.origin}/users/${userId}`;
    if (navigator.share) {
      navigator.share({
        title: `${user?.firstName} ${user?.lastName}'s Profile`,
        url: profileUrl,
      }).catch(console.error);
    } else {
      navigator.clipboard.writeText(profileUrl).then(() => {
        alert("Profile link copied to clipboard!");
      });
    }
  };




  const handleCrop = () => {
    const cropper = cropperRef.current?.cropper;
    const croppedImageData = cropper.getCroppedCanvas().toDataURL();
    setCroppedImage(croppedImageData);
  };


  const handleProfilePictureUpload = async () => {
    if (croppedImage) {
      // Convert the cropped image to a Blob
      const blob = dataURLtoBlob(croppedImage);

      const formData = new FormData();
      formData.append('profilePicture', blob, 'profilePicture.jpg'); // Append Blob as a file

      try {
        setUploading(true);
        const response = await axios.put(`${apiBaseURL}/api/admin/superuser/profile-picture`, formData, {
          headers: {
            'x-auth-token': token,
            'Content-Type': 'multipart/form-data',
          }
        });
        onUpdateProfilePicture(response.data.profilePicture);
        setUploading(false);
        setUploadSuccess(true);
        setPreview(null);
        setOpenCropperModal(false); // Close the cropper modal after upload
      } catch (error) {
        console.error('Error updating profile picture:', error);
        setUploading(false);
      }
    }
  };

  // Helper function to convert data URL to Blob
  const dataURLtoBlob = (dataURL) => {
    const byteString = atob(dataURL.split(',')[1]); // Decode base64
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0]; // Extract mime type

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  };

  const isCurrentUser = loggedInUser?.id?.toString() === user?._id?.toString();
  const isSuperUser = user?.role === 'super-user';
  const showUploadButton = isCurrentUser && isSuperUser;

  const photos = posts.map(post => ({ source: `${apiBaseURL}/uploads/${post.mediaPath}` }));

  // Handle modal open
  const handleOpenModal = (type) => {
    setModalType(type); // 'followers' or 'following'
    setOpenModal(true);
  };

  // Handle modal close
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Box
      sx={{
        padding: 2,
        background: "rgba(0, 0, 0, 0.87)",
        borderRadius: 2,
        boxShadow: 2,
        '@media (max-width: 600px)': {
          padding: 1,
          marginTop:'1vh'
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 2,
          position: 'relative',
          backgroundColor: "#15171a",
          padding: 2,
          '@media (max-width: 600px)': {
            flexDirection: 'column', // Stack elements vertically
            alignItems: 'center',   // Center items horizontally
            padding: 1,
          },
        }}
      >
        {/* Avatar Image and Upload */}
        <Box
          sx={{
            position: 'relative',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center', // Ensure horizontal centering
            '@media (max-width: 600px)': {
              width: '100%',         // Take full width
              justifyContent: 'center', // Center avatar
            },
          }}
          onClick={() => fileInputRef.current.click()}
        >
          <Avatar
            src={preview || `${apiBaseURL}/uploads/${user?.profilePicture}` || "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"}
            sx={{ width: 80, height: 80, marginBottom:'1vh' }}
          />
          {/* Show Upload Button */}
          {showUploadButton && (
            <>
              <input
                type="file"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                ref={fileInputRef}
              />
              {!preview && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    color: 'white',
                    borderRadius: '50%',
                    opacity: 0,
                    transition: 'opacity 0.3s',
                    '&:hover': {
                      opacity: 1,
                    },
                  }}
                >
                  <PhotoCamera />
                </Box>
              )}
            </>
          )}
        </Box>
        <Box sx={{ marginLeft: 2 }}>
          {/* Flex container to align user details and button */}
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
            {/* User details */}
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h6" style={{ color: "#D3D3D3" }}>
                {user?.firstName + ' ' + user?.lastName}
              </Typography>
              <Typography variant="body2" color="textSecondary" style={{ color: "#D3D3D3" }}>
                {user?.contactInfo.address.city || "Bay Area, San Francisco, CA"}
              </Typography>
            </Box>
            {/* Share Profile Button */}
            <IconButton color="primary" onClick={handleShareProfile} title="Share Profile">
              <Share sx={{ color: 'white' }} />
            </IconButton>

            {/* Follow/Unfollow Button */}
            {/* Follow/Unfollow Icon */}
            {userId !== userlocalId && (
              <IconButton
                onClick={isFollowing ? handleUnfollow : handleFollow}
                sx={{ marginLeft: 2, color: isFollowing ? 'error.main' : 'white', '@media (max-width: 600px)': { marginLeft: 1 } }}
              >
                {isFollowing ? <UnfollowIcon /> : <FollowIcon />}
              </IconButton>
            )}
          </Box>

          {/* Followers and Following Count */}
          {/* Followers and Following count with modal button */}
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'white', fontSize: '1rem' }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: { xs: 'column', sm: 'row' }, // Switch to column on small screens (xs)
              textAlign: { xs: 'center', sm: 'left' } // Center text on small screens
            }}>
              Followers:
              <Button
                onClick={() => handleOpenModal('followers')}
                style={{ color: "white", padding: 0, margin: 0, textTransform: 'none', width: '0.1px' }}
              >
                {followers.length}
              </Button>
              <span style={{ color: 'white', fontSize: '1rem' }}>
                Following:
              </span>
              <Button
                onClick={() => handleOpenModal('following')}
                style={{ color: "white", padding: 0, margin: 0, textTransform: 'none', width: '2px' }}
              >
                {following.length}
              </Button>
            </Box>
          </Typography>
          {showUploadButton && preview && (
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
              <Button
                onClick={handleProfilePictureUpload}
                variant="contained"
                sx={{ marginRight: 1, backgroundColor: 'green' }}
                disabled={uploading}
              >
                {uploading ? <CircularProgress size={24} /> : 'Confirm'}
              </Button>
              <Button
                onClick={() => setPreview(null)}
                variant="outlined"
                sx={{ marginRight: 1, backgroundColor: 'white' }}
              >
                Cancel
              </Button>
              {uploadSuccess && (
                <CheckCircleOutlineIcon color="success" sx={{ marginLeft: 1 }} />
              )}
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
        {photos.length > 0 ? (
          <CustomPhotoCollage photos={photos} />
        ) : (
          <Typography style={{ color: "#D3D3D3" }}>No posts to display</Typography>
        )}
      </Box>
      {/* <Box> */}
      <UserProfile style={{ width: '100%' }} />
      {/* </Box> */}
      {/* Modal for Followers and Following */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>{modalType === 'followers' ? 'Followers' : 'Following'}</DialogTitle>
        <DialogContent>
          <List>
            {(modalType === 'followers' ? followers : following).map((user, index) => (
              <ListItem key={index}>
                <Avatar sx={{ marginRight: 2 }} src={`${apiBaseURL}/uploads/${user.profilePicture}`} />
                <ListItemText primary={`${user.firstName} ${user.lastName}`} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

      {/* Cropper Modal */}
      <Dialog open={openCropperModal} onClose={() => setOpenCropperModal(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Crop Profile Picture</DialogTitle>
        <DialogContent>
          <Cropper
            src={preview}
            style={{ width: '100%', height: 400 }}
            aspectRatio={1}
            preview=".img-preview"
            guides={false}
            ref={cropperRef}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCrop} color="primary">Crop</Button>
          <Button onClick={handleProfilePictureUpload} color="primary">Upload</Button>
          <Button onClick={() => setOpenCropperModal(false)} color="secondary">Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
