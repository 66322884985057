import { configureStore } from "@reduxjs/toolkit";
import appStateSlice from "./features/appStateSlice";
import authModalSlice from "./features/authModalSlice";
import globalLoadingSlice from "./features/globalLoadingSlice";
import themeModeSlice from "./features/themeModeSlice";
import userSlice from "./features/userSlice";
// import rootReducer from "./Reducers/combinedReducers";
import { personalInfoReducer } from "./features/personalInfoSlice";
import { keySkillsReducer } from "./features/keySkillsSlice";
import { workExperienceReducer } from "./features/workExperienceSlice";
import { educationDetailsReducer } from "./features/educationDetailsSlice";

const store = configureStore({
  reducer: {
    user: userSlice,
    themeMode: themeModeSlice,
    authModal: authModalSlice,
    globalLoading: globalLoadingSlice,
    appState: appStateSlice,
    personalInfoReducer:personalInfoReducer,
    keySkillsReducer:keySkillsReducer,
    educationDetailsReducer:educationDetailsReducer,
    workExperienceReducer:workExperienceReducer
  }
});



export default store;