import React from 'react';
import HeroSlide from '../components/common/HeroSlide';
import tmdbConfigs from "../api/configs/tmdb.configs";
import { Box } from '@mui/material';
import Container from "../components/common/Container";
import MediaSlide from "../components/common/MediaSlide";
import Sizzlers from '../components/common/sizzlers';
import AuditionSlide from '../components/common/auditionSlide';
import { useEffect, useState } from "react";
import { useMediaQuery } from '@mui/material';
import BannerSlide from '../components/common/BannerSlide';
import logo from '../assets/motionlogo.mp4';


const HomePage = () => {
  const [location, setLocation] = useState(null);
  const [state, setState] = useState('Unknown State');
  const userData = JSON.parse(localStorage.getItem("user"));
  const userId = userData?.id;
  const [bannersAvailable, setBannersAvailable] = useState(false);
  const [marginTop, setMarginTop] = useState("5vh");

  // State for loading
  const [loading, setLoading] = useState(true);

  // Media query for screen width <= 600px and height <= 858px
  const showH1 = useMediaQuery('(max-width: 600px) and (max-height: 858px)');

  useEffect(() => {
    setMarginTop(bannersAvailable ? 0 : "5vh");
  }, [bannersAvailable]);

   // Simulate loading data with a timeout
   useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Set loading to false after 5 seconds
    }, 7000);

    return () => clearTimeout(timer); // Clean up the timer
  }, []);

  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          fetchStateFromCoordinates(latitude, longitude);
          updateUserState(latitude, longitude); // Call updateUserState here
        },
        (error) => {
          console.error("Error getting geolocation: ", error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const fetchStateFromCoordinates = async (latitude, longitude) => {
    try {
      const response = await fetch(`${apiBaseURL}/api/users/geolocation?latitude=${latitude}&longitude=${longitude}`);
      const data = await response.json();
      const state = data?.address?.state || "Unknown State";
      setState(state);
    } catch (error) {
      console.error("Error fetching state from backend:", error);
    }
  };

  const updateUserState = async (latitude, longitude) => {
    const userId = getUserIdFromLocalStorage();
    if (!userId) {
      console.error("User ID is not available");
      return;
    }

    try {
      const response = await fetch(`${apiBaseURL}/api/users/${userId}/state-from-geolocation`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ latitude, longitude }),
      });

      const data = await response.json();

      if (response.ok) {
        console.log("User state updated successfully", data);
      } else {
        console.error("Failed to update user state", data);
      }
    } catch (error) {
      console.error("Error updating user state:", error);
    }
  };

  const getUserIdFromLocalStorage = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return user ? user.id : null;
  };

  return (
    <>

{loading && (
  <Box
    sx={{
      position: 'fixed', // Make sure the video stays fixed on the screen
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'black',
      zIndex: '9999', // Ensure it's above all other elements
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden', // Prevent scrolling while loading
    }}
  >
    <video src={logo} style={{ height: '100%', width: '100%' }} autoPlay muted loop></video>
  </Box>
)}



      <BannerSlide onBannersAvailable={(available) => setBannersAvailable(available)} />
      <HeroSlide mediaType={tmdbConfigs.mediaType.movie} mediaCategory={tmdbConfigs.mediaCategory.popular} sx={{ marginTop: marginTop }}/>
      {/* <Sizzlers mediaType={tmdbConfigs.mediaType.movie} mediaCategory={tmdbConfigs.mediaCategory.popular} /> */}

      {/*  */}

      {/* Conditionally pass showHeader prop */}
      {/* <Container header="Auditions..." showHeader={!showH1}>
        <Box style={{ marginTop: '7vh', }}>
          <h1 style={{textAlign:'center', color:'wheat'}}> Comming soon...</h1> */}
      {/* <AuditionSlide mediaType={tmdbConfigs.mediaType.movie} mediaCategory={tmdbConfigs.mediaCategory.popular} /> */}
      {/* </Box>
      </Container> */}

      {/* Display h1 only on screens smaller than or equal to 600x858 */}
      {showH1 && (
        <Box
          sx={{
            display: 'block',
            textAlign: 'left',
            marginLeft: '3vw',
          }}
        >
          <h1 style={{ margin: 0, color: 'white', fontSize: '1rem' }}>Trending Now</h1>
        </Box>
      )}

      {/* Show container content, but hide the header when showH1 is true */}
      <Container header="Trending Now..." showHeader={!showH1}>
        <MediaSlide mediaType={tmdbConfigs.mediaType.movie} mediaCategory={tmdbConfigs.mediaCategory.popular} header="Trending Now" />
      </Container>

      {showH1 && (
        <Box
          sx={{
            display: 'block',
            textAlign: 'left',
            marginLeft: '3vw',
          }}
        >
          <h1 style={{ margin: 0, color: 'white', fontSize: '1rem' }}>Up Coming</h1>
        </Box>
      )}

      <Container header="Up Coming..." showHeader={!showH1}>
        <MediaSlide mediaType={tmdbConfigs.mediaType.upcoming} mediaCategory={tmdbConfigs.mediaCategory.popular} header="Up Coming" />
      </Container>

      {showH1 && (
        <Box
          sx={{
            display: 'block',
            textAlign: 'left',
            marginLeft: '3vw',
          }}
        >
          <h1 style={{ margin: 0, color: 'white', fontSize: '1rem' }}>Movies</h1>
        </Box>
      )}

      <Container header="Movies..." showHeader={!showH1}>
        <MediaSlide mediaType={tmdbConfigs.mediaType.movie} mediaCategory={tmdbConfigs.mediaCategory.top_rated} header="Movies" />
      </Container>

      {showH1 && (
        <Box
          sx={{
            display: 'block',
            textAlign: 'left',
            marginLeft: '3vw',
          }}
        >
          <h1 style={{ margin: 0, color: 'white', fontSize: '1rem' }}>Webseries</h1>
        </Box>
      )}

      <Container header="Web Series..." showHeader={!showH1}>
        <MediaSlide mediaType={tmdbConfigs.mediaType.webseries} mediaCategory={tmdbConfigs.mediaCategory.top_rated} header="Web Series" />
      </Container>

      {showH1 && (
        <Box
          sx={{
            display: 'block',
            textAlign: 'left',
            marginLeft: '3vw',
          }}
        >
          <h1 style={{ margin: 0, color: 'white', fontSize: '1rem' }}>Web Shows</h1>
        </Box>
      )}

      <Container header="Web Shows..." showHeader={!showH1}>
        <MediaSlide mediaType={tmdbConfigs.mediaType.webshows} mediaCategory={tmdbConfigs.mediaCategory.top_rated} header="Web Shows" />
      </Container>

      {showH1 && (
        <Box
          sx={{
            display: 'block',
            textAlign: 'left',
            marginLeft: '3vw',
          }}
        >
          <h1 style={{ margin: 0, color: 'white', fontSize: '1rem' }}>Shows</h1>
        </Box>
      )}

      <Container header="Shows..." showHeader={!showH1}>
        <MediaSlide mediaType={tmdbConfigs.mediaType.shows} mediaCategory={tmdbConfigs.mediaCategory.top_rated} header="Shows" />
      </Container>

      {showH1 && (
        <Box
          sx={{
            display: 'block',
            textAlign: 'left',
            marginLeft: '3vw',
          }}
        >
          <h1 style={{ margin: 0, color: 'white', fontSize: '1rem' }}>Music Videos</h1>
        </Box>
      )}

      <Container header="Music Videos..." showHeader={!showH1}>
        <MediaSlide mediaType={tmdbConfigs.mediaType.musicvideos} mediaCategory={tmdbConfigs.mediaCategory.popular} header="Music Videos" />
      </Container>


      {/* {showH1 && (
        <Box
          sx={{
            display: 'block',
            textAlign: 'left',
            marginLeft: '3vw',
          }}
        >
          <h1 style={{ margin: 0, color: 'white', fontSize: '1rem' }}>Your Favorite</h1>
        </Box>
      )} */}

      {/* {userId && (
        <Container header="Your Favorite..." showHeader={!showH1}>
          <MediaSlide mediaType="favorites" userId={userId} />
        </Container>
      )} */}


      {/* {showH1 && (
        <Box
          sx={{
            display: 'block',
            textAlign: 'left',
            marginLeft: '3vw',
          }}
        >
          <h1 style={{ margin: 0, color: 'white', fontSize: '2rem' }}>Watchlist</h1>
        </Box>
      )}

      <Container header="Watch List..." showHeader={!showH1}>
        <MediaSlide mediaType="viewed" userId={userId} />
      </Container> */}

      {showH1 && (
        <Box
          sx={{
            display: 'block',
            textAlign: 'left',
            marginLeft: '3vw',
          }}
        >
          <h1 style={{ margin: 0, color: 'white', fontSize: '1rem' }}>Top Tales This Week</h1>
        </Box>
      )}

      <Container header="Top Tales In This Week..." showHeader={!showH1}>
        <MediaSlide mediaType={tmdbConfigs.mediaType.toptalesthisweek} mediaCategory={tmdbConfigs.mediaCategory.top_rated} header="Top Tales In This Week" />
      </Container>

      {showH1 && (
        <Box
          sx={{
            display: 'block',
            textAlign: 'left',
            marginLeft: '3vw',
          }}
        >
          <h1 style={{ margin: 0, color: 'white', fontSize: '1rem' }}>Short Films</h1>
        </Box>
      )}

      <Container header="Short Films..." showHeader={!showH1}>
        <MediaSlide mediaType={tmdbConfigs.mediaType.shortfilms} mediaCategory={tmdbConfigs.mediaCategory.top_rated} header="Short Films" />
      </Container>


      {/* Add h1 fallback for Web Series when showH1 is true */}
      {/* {showH1 && (
        <Box sx={{ display: 'block', textAlign: 'center', margin: '2rem 0' }}>
          <h1>Web Series</h1>
        </Box>
      )} */}
    </>
  );
};

export default HomePage;
