import { useTheme } from '@mui/material';
import LogoImage from "../../assets/logo.jpg"
import { Box } from '@mui/material';

const Logo = () => {
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center" position="relative">
      <img src={LogoImage} alt="Logo" style={{ width: '200px', marginRight: '10px', marginLeft:'2vw' }} />
    </Box>
  );
};

export default Logo;
