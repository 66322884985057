import React, { useEffect, useState } from 'react';
import { getAllFavorites } from '../api/modules/favorite.api'; // Adjust the import path as necessary
import MediaGrid from '../components/common/MediaGrid'; // Reuse your MediaGrid component

const Favorites = () => {
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [mediaType, setMediaType] = useState("favorites"); // Default to "favorites"

  useEffect(() => {
    const fetchFavorites = async () => {
      // Retrieve user data from local storage
      const user = JSON.parse(localStorage.getItem('user'));
      
      if (!user || !user.id) {
        setError(new Error("User not found in local storage"));
        setLoading(false);
        return;
      }

      const { response, err } = await getAllFavorites(user.id); // Use the actual user ID
      if (err) {
        setError(err);
      } else {
        setFavorites(response);
        // Set mediaType from the first item's type if available
        if (response.length > 0 && response[0].type) {
          setMediaType(response[0].type);
        }
      }
      setLoading(false);
    };

    fetchFavorites();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error fetching favorites: {error.message}</div>;

  return (
    <div style={{marginTop:"10vh"}}>
      <h2 style={{color:"white"}}>Your Favorites</h2>
      {favorites.length === 0 ? (
        <div>No favorites added yet.</div>
      ) : (
        <MediaGrid medias={favorites} mediaType={mediaType} />
      )}
    </div>
  );
};

export default Favorites;
