import HomePage from "../pages/HomePage";
import PersonDetail from "../pages/PersonDetail";
import FavoriteList from "../pages/FavoriteList";
import MediaDetail from "../pages/MediaDetail";
import MediaList from "../pages/MediaList";
import MediaSearch from "../pages/MediaSearch";
import PasswordUpdate from "../pages/PasswordUpdate";
import ReviewList from "../pages/ReviewList";
import ProtectedPage from "../components/common/ProtectedPage";
import DetailsFilling from "../pages/DetailsFilling";
import ProfilePage from "../ProfilePage/profileMain";
import Login from "../components/common/Login/login";
import Signup from "../components/common/Signup/signup";
// import NewPage from "../pages/NewPage";
import PlayVideo from "../pages/NewPage";
import PaymentPage from "../pages/payment";
import PaymentForm from "../pages/ticket";
import Details from "../pages/Details";
import SizzlersVid from "../components/SizzlersVideo/SizzlersVid";
import CastPortfolio from "../pages/CastPortFolio";
import ProfileUpdate from "../pages/ProfileSettings";
import UserAnalyticsPage from "../pages/Analytics";
import UserLandingPage from "../pages/Myprofile";
import ArtistPage from "../pages/PersonDetails";
import ContactForm from "../pages/ContactForm";
import AboutUs from "../pages/Aboutus";
import CmsHomeuser from "../pages/CMScomponents/CmsHomePage";
import CMSSuperUserForm from "../pages/CMScomponents/CMSSuperuserForm";
import CMSMediaForm from "../pages/CMScomponents/CMSMediaForm";
import CmsUserPosts from "../pages/CMScomponents/CmsPosts";
import CmsSizzlers from "../pages/CMScomponents/CmsSizzlers";
import UserMoviesPage from "../pages/CMScomponents/UserMoviesPage";
import ForgotPassword from "../components/common/ForgotPassword/ForgotPassword";
import AddMovieToUser from "../pages/CMScomponents/AddMediaToUser";
import CommunityPage from "../pages/CommunityPage";
import PostDetail from "../ProfilePage/Component-4/PostDetails";

export const routesGen = {
  home: "/",
  mediaList: (type) => `/${type}`,
  mediaDetail: (type, id) => `/${type}/${id}`,
  mediaSearch: "/search",
  person: (id) => `/person/${id}`,
  favoriteList: "/favorites",
  reviewList: "/reviews",
  passwordUpdate: "password-update"
};

const routes = [
  {
    index: true,
    element: <HomePage />,
    state: "home"
  },
  {
    path: "/profilesettings",
    element: <ProfileUpdate />,
    state: "profile.update"
  },
  {
    path: "/users/:userId",
    element: <ProfilePage/>

  },
  {
    path: "/login",
    element: <Login />,
    state: "login"
  },
  // Route for Signup
  {
    path: "/signup",
    element: <Signup />,
    state: "signup"
  },
  {
    path: "/person/:personId",
    element: <PersonDetail />,
    state: "person.detail"
  },
  {
    path: '/details',
    element: <Details />,
    state: "details" // Optionally, provide a state if needed
  },
  {
    path: "/search",
    element: <MediaSearch />,
    state: "search"
  },
  {
    path: "/password-update",
    element: (
      <ProtectedPage>
        <PasswordUpdate />
      </ProtectedPage>
    ),
    state: "password.update"
  },
  {
    path: "/favorites",
    element: (
        <FavoriteList />
    ),
    state: "favorites"
  },
  {
    path: "/reviews",
    element: (
      <ProtectedPage>
        <ReviewList />
      </ProtectedPage>
    ),
    state: "reviews"
  },
  {
    path: "/:mediaType",
    element: <MediaList />
  },
  {
    path: "/:mediaType/:mediaId",
    element: <MediaDetail />
  },
  {
    path: ":mediaType/:mediaId/video",
    element: <PlayVideo />
  },
  {
    path: "/subscription",
    element: <PaymentPage />
  },
  {
    path: "/ticket",
    element: <PaymentForm />
  },
  {
    path: "/video/:videoId",
    element: <SizzlersVid />
  },
  {
    path: "/cast/:castId",
    element: <CastPortfolio />
  },
  {
    path: "/analytics/:userId",
    element: <UserAnalyticsPage />
  },
  {
    path: "/myprofile",
    element: <UserLandingPage />
  },
  {
    path: "/artists",
    element: <ArtistPage />
  },
  {
    path: "/contactus",
    element: <ContactForm />
  },
  {
    path: "/aboutus",
    element: <AboutUs />
  },
  {
    path: "/cmshome",
    element: <CmsHomeuser />
  },
  {
    path: "/cmsform",
    element: <CMSSuperUserForm />
  },
  {
    path: "/cmsform",
    element: <CMSSuperUserForm />
  },
  {
    path: "/cmsmedia-form",
    element: <CMSMediaForm />
  },
  {
    path: "/cmsmedia-form",
    element: <CMSMediaForm />
  },
  {
    path: "/cmsposts",
    element: <CmsUserPosts />
  },
  {
    path: "/cmssizzlers",
    element: <CmsSizzlers />
  },
  {
    path: "/cmsuploadedmedia",
    element: <UserMoviesPage />
  },
  {
    path: "/sizzler/:id",
    element: <SizzlersVid/>
  },
  {
    path: "forgetpassword",
    element: <ForgotPassword/>
  },
  {
    path: "addmedia",
    element: <AddMovieToUser/>
  },
  {
    path: "community",
    element: <CommunityPage/>
  },
  {
    path: "/sharedpost/:postId",
    element: <PostDetail/>
  }
  // {
  //   path: "/tctadmin-dashboard",
  //   element: <TCTMoviesPendingApproval />
  // },
  // {
  //   path: "/tctadmin-dashboard",
  //   element: <TCTMoviesPendingApproval />
  // },
  // {
  //   path: "/tctsuperuserapproval",
  //   element: <TCTUsersToApprove />
  // },

  // {
  //   path: "/tctadminpost-approval",
  //   element: <TCTAdminPostApproval />
  // },


  // <Route path="/post-approval" element={<AdminPostApproval />} />

  // <Route path="/cmshome" element={<Homeuser/>} />

];

export default routes;