import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Typography, Grid, Avatar, IconButton, CardMedia } from '@mui/material';
import { Link } from 'react-router-dom';

const RelatedArtists = () => {
    const { userId } = useParams();  // Get the user ID from the URL
    const [user, setUser] = useState(null);
    const [superuser, setSuperuser] = useState(null);
    const [relatedArtists, setRelatedArtists] = useState([]);

    // Fetch user data by ID from the URL
    const fetchUserData = async () => {
        try {
            const { data } = await axios.get(`https://backend.thecameratales.com/api/admin/users/${userId}`);
            console.log("Fetched user data:", data);  // Log user data
            setUser(data);
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    // Fetch superuser data
    const fetchSuperuserData = async () => {
        try {
            const { data } = await axios.get('https://backend.thecameratales.com/api/admin/superuser');
            console.log("Fetched superuser data:", data);  // Log superuser data
            setSuperuser(data);
        } catch (error) {
            console.error("Error fetching superuser data:", error);
        }
    };

    const getRelatedArtists = () => {
        if (user && superuser) {
            const userCategories = Array.isArray(user.categories) && user.categories.length > 0 ? user.categories : ['General'];
            const superuserCategories = Array.isArray(superuser.categories) && superuser.categories.length > 0 ? superuser.categories : ['General'];

            console.log("User categories:", userCategories);
            console.log("Superuser categories:", superuserCategories);

            // Find related categories based on user and superuser category match
            const related = superuserCategories.filter(superuserCategory =>
                userCategories.some(userCategory => userCategory.toLowerCase().includes(superuserCategory.toLowerCase()))
            );

            console.log("Related categories:", related);

            // Check if superuser is an array (multiple superusers)
            if (Array.isArray(superuser)) {
                // Filter the superusers based on category match
                const relatedArtistsData = superuser.filter(artist =>
                    artist.categories && artist.categories.some(artistCategory =>
                        related.includes(artistCategory)
                    )
                );

                // If there are related artists, pick the first one
                const selectedArtist = relatedArtistsData.length > 0 ? relatedArtistsData[0] : superuser[0];  // Fall back to the first superuser

                console.log("Selected related artist:", selectedArtist);

                // Set the state with the selected related artist
                setRelatedArtists([selectedArtist]);
            } else {
                // If superuser is a single object, use the existing logic for one superuser
                const relatedArtistsData = superuser.categories && superuser.categories.some(artistCategory =>
                    related.includes(artistCategory)
                ) ? [superuser] : [superuser];  // Fallback to showing the superuser

                console.log("Related artists data:", relatedArtistsData);

                // Set the state with the filtered related artist
                setRelatedArtists(relatedArtistsData);
            }
        }
    };

    // Fetch data on component mount
    useEffect(() => {
        console.log("Fetching data for userId:", userId);  // Log the userId for debugging
        fetchUserData();
        fetchSuperuserData();
    }, [userId]);

    // Run category matching after data is fetched
    useEffect(() => {
        if (user && superuser) {
            console.log("User and superuser data loaded, processing related artists...");
            getRelatedArtists();
        }
    }, [user, superuser]);

    return (
        <div style={{ width: '100%', marginLeft: '1vw', marginTop: '2vh', display: 'flex', justifyContent: 'center' }}> {/* Adjust width here */}
            <Typography variant="h6" sx={{ color: 'white', mb: 2, mt: 2, }}>
                {['People', 'who', 'search', 'for', 'this', 'profile', 'also', 'search'].map((word, index) => (
                    <Typography key={index} sx={{ color: 'white', mb: 0, mt: 0, fontSize:'1.8rem', mr:2 }}>
                        {word}
                    </Typography>
                ))}
            </Typography>

            {relatedArtists.length === 0 ? (
                <Typography sx={{ color: '#A9A9A9' }}>
                    No related artists found.
                </Typography>
            ) : (
                <Grid container spacing={3}>
                    {relatedArtists.map((artist, index) => {
                        // Ensure the profile picture exists, fallback to placeholder if undefined
                        const imageUrl = artist.profilePicture
                            ? `https://backend.thecameratales.com/uploads/${artist.profilePicture}`
                            : 'https://via.placeholder.com/200';  // Placeholder image URL

                        console.log("Artist Image URL:", imageUrl);  // Log the image URL

                        return (
                            <Grid item key={index}>
                                <Card sx={{ backgroundColor: '#2c2f33', borderRadius: 2, p: 2 }}>
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        image={imageUrl}
                                        alt={`${artist.firstName} ${artist.lastName}`}
                                        sx={{ borderRadius: 2 }}
                                    />
                                    <CardContent>
                                        <Typography variant="h6" sx={{ color: 'white' }}>
                                            {artist.firstName} {artist.lastName}
                                        </Typography>
                                        <Typography sx={{ color: '#A9A9A9' }}>
                                            {Array.isArray(artist.categories) ? artist.categories.join(', ') : 'No categories available'}
                                        </Typography>
                                        <Link to={`/users/${artist._id}`}>
                                            <IconButton sx={{ color: '#D3D3D3' }}>View Profile</IconButton>
                                        </Link>
                                    </CardContent>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            )}
        </div>
    );
};

export default RelatedArtists;
