import React, { useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { VideoContext } from '../Context/VideoContext';

const EpisodesSlider = ({ episodes = [], onEpisodeClick }) => {
    const { setSelectedVideo } = useContext(VideoContext);
    const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

    // Check if episodes is an array
    if (!Array.isArray(episodes)) {
        console.error("Episodes prop is not an array", episodes);
        return <p>There was an error loading episodes.</p>; // Optional: Show a fallback UI
    }

    return (
        <div style={{ padding: '20px', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <h2 style={{ color: 'rgba(255, 255, 255, 0.5)', textAlign: 'center', marginBottom: '20px' }}>
                Episodes
            </h2>
            <Swiper
                spaceBetween={20}
                slidesPerView={5}
                navigation
                breakpoints={{
                    640: { slidesPerView: 1, spaceBetween: 10 },
                    768: { slidesPerView: 2, spaceBetween: 20 },
                    1024: { slidesPerView: 3, spaceBetween: 30 },
                }}
                style={{ width: '100%', height: '100%' }}
            >
                {episodes.map((episode) => (
                    <SwiperSlide key={episode._id}>
                        <div
                            onClick={() => {
                                setSelectedVideo(episode);
                                onEpisodeClick(episode.video_urls.master);
                            }}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                borderRadius: '12px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                overflow: 'hidden',
                                textAlign: 'center',
                                cursor: 'pointer',
                            }}
                        >
                            <img
                                src={`${apiBaseURL}/uploads/${episode.poster_path}`}
                                alt={episode.title}
                                style={{
                                    width: '100%',
                                    height: '20vh',
                                    borderBottom: '2px solid #ddd',
                                    objectFit: 'cover',
                                }}
                            />
                            <div style={{ padding: '15px' }}>
                                <h3 style={{ fontSize: '1.2rem', margin: '0 0 10px 0', color: 'rgba(0, 0, 0, 0.8)' }}>
                                    {episode.title}
                                </h3>
                                <p style={{ fontSize: '0.9rem', color: '#666' }}>{episode.overview}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default EpisodesSlider;
