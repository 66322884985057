import { combineReducers } from '@reduxjs/toolkit';
// import personalInfoReducer from './personalInfoSlice';
import { keySkillsReducer, addNewSkills, editSkill, deleteSkill } from './keySkillsSlice';
import { workExperienceReducer, addExperience, addAllExperience } from './workExperienceSlice';
import { educationDetailsReducer, addEducation } from './educationDetailsSlice';
import { addPersonalInfo } from './personalInfoSlice';

const rootReducer = combineReducers({
  // personalInfo: personalInfoReducer,
  keySkillsReducer,
  workExperience: workExperienceReducer,
  educationDetails: educationDetailsReducer,
});

export {
  addNewSkills,
  editSkill,
  deleteSkill,
  addExperience,
  addAllExperience,
  addEducation,
  addPersonalInfo,
};

export default rootReducer;
