import "../Styles/KeyskillsSection.css";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Paper, Divider, Button } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { connect } from "react-redux";
import { BackNextButton, Input } from "../../pages/index";
import { addNewSkills, deleteSkill, editSkill } from "../../redux/features/keySkillsSlice";
import SubmitButton from './SubmitButton'; // Import the SubmitButton component

const KeyskillsSection = (props) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleBack = () => {
    props.setTab(props.tab - 1);
  };

  return (
    <Paper elevation={3} className="key-skills-paper">
      <h2 className="key-skills-heading">Key Skills</h2>
      <Divider />
      <form onSubmit={handleSubmit(props.onNext)}> {/* Use props.onNext for form submission */}
        <div className="key-skills-form-fields">
          {props.skills.map((skill, index) => (
            <div key={index} className="key-input-with-delete">
              <Input
                type="text"
                name={`skill${index + 1}`}
                register={register}
                multiline={false}
                value={skill}
                setValue={(newValue) => props.onEditSkill(newValue, index)}
                error={errors[`skill${index + 1}`] ? true : false}
                errorMessage={
                  errors[`skill${index + 1}`]
                    ? errors[`skill${index + 1}`].message
                    : null
                }
              />
              {props.skills.length === 1 ? null : (
                <DeleteOutlineOutlinedIcon
                  sx={{ marginLeft: "10px" }}
                  onClick={() => props.onDeleteSkill(index)}
                />
              )}
            </div>
          ))}
        </div>
        {props.skills.length >= 6 ? null : (
          <Button className="add-new-btn" variant="text" onClick={props.onAddNewSkill}>
            Add new
          </Button>
        )}
        <Divider className="key-skills-divider" />
        <SubmitButton /> {/* Use the SubmitButton component here */}
      </form>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  skills: state.keySkillsReducer.skills,
});

const mapDispatchToProps = (dispatch) => ({
  onAddNewSkill: () => dispatch(addNewSkills()),
  onEditSkill: (value, id) => dispatch(editSkill({ id, value })),
  onDeleteSkill: (index) => dispatch(deleteSkill(index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(KeyskillsSection);
