import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import store from './redux/store';
import App from './App';
import { UserProvider } from "./Context/UserContext";


// Fonts and styles
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import 'bootstrap/dist/css/bootstrap.min.css';

// App initialization
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
   <UserProvider>
    <App />
  </UserProvider>,
  </Provider>
);
