import { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const Signup = () => {
    const [data, setData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
    });
    const [otpData, setOtpData] = useState({
        otp: "",
        isOtpSent: false,
        isOtpVerified: false,
    });
    const [error, setError] = useState("");
    const [attemptedSignup, setAttemptedSignup] = useState(false);
    const [otpResendMessage, setOtpResendMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
    const navigate = useNavigate();

    const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

    const handleChange = ({ target }) => {
        setData({ ...data, [target.name]: target.value });
    };

    const handleOtpChange = ({ target }) => {
        setOtpData({ ...otpData, [target.name]: target.value });
    };

    const handleSendOtp = async () => {
        try {
            const url = `${apiBaseURL}/api/users/send-otp`;
            const { data: res } = await axios.post(url, { email: data.email });
            console.log(res.message);
            setOtpData({ ...otpData, isOtpSent: true });
        } catch (error) {
            setError("Error sending OTP. Please try again.");
        }
    };

    const handleResendOtp = async () => {
        try {
            const url = `${apiBaseURL}/api/users/resend-otp`;
            const { data: res } = await axios.post(url, { email: data.email });
            console.log(res.message);
            setOtpResendMessage("OTP resent successfully. Check your email!");
        } catch (error) {
            setError("Error resending OTP. Please try again.");
        }
    };

    const handleVerifyOtp = async () => {
        try {
            const url = `${apiBaseURL}/api/users/verify-otp`;
            const { data: res } = await axios.post(url, {
                email: data.email,
                otp: otpData.otp,
            });
            console.log(res.message);
            if (res.message === "OTP verified successfully") {
                setOtpData({ ...otpData, isOtpVerified: true });
            } else {
                setError("Error verifying OTP. Please try again.");
            }
        } catch (error) {
            setError("Error verifying OTP. Please try again.");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setAttemptedSignup(true);

        try {
            if (!otpData.isOtpSent || !otpData.isOtpVerified) {
                setError("Please send and verify the OTP.");
                return;
            }

            if (!data.password) {
                setError("Please enter a password.");
                return;
            }

            const url = `${apiBaseURL}/api/users`;
            const { data: res } = await axios.post(url, {
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                password: data.password,
                otp: otpData.otp,
            });

            console.log(res.message);
            navigate("/login");
        } catch (error) {
            console.error(error);
            setError("Internal Server Error");
        }
    };

    return (
        <div className={styles.signup_container}>
            <div className={styles.signup_form_container}>
                <div className={styles.left}>
                    <h1 className={styles.head}>Already a member?</h1>
                    <Link to="/login">
                        <button type="button" className={styles.white_btn}>
                            Sign in
                        </button>
                    </Link>
                </div>
                <div className={styles.right} style={{ margin: "10px" }}>
                    <form className={styles.form_container} onSubmit={handleSubmit}>
                        <h1 className={styles.head}>Create Account</h1>
                        <input
                            type="text"
                            placeholder="First Name"
                            name="firstName"
                            onChange={handleChange}
                            value={data.firstName}
                            required
                            className={styles.input}
                        />
                        <input
                            type="text"
                            placeholder="Last Name"
                            name="lastName"
                            onChange={handleChange}
                            value={data.lastName}
                            required
                            className={styles.input}
                        />
                        <div className={styles.email_input_container}>
                            <input
                                type="email"
                                placeholder="Email"
                                name="email"
                                onChange={handleChange}
                                value={data.email}
                                required
                                className={styles.inputemail}
                            />
                            {!otpData.isOtpSent && (
                                <button
                                    type="button"
                                    className={styles.send_otp_btn}
                                    onClick={handleSendOtp}
                                >
                                    Send OTP
                                </button>
                            )}
                        </div>
                        {otpData.isOtpSent && (
                            <>
                                <input
                                    type="text"
                                    placeholder="Enter OTP"
                                    name="otp"
                                    onChange={handleOtpChange}
                                    value={otpData.otp}
                                    required
                                    className={styles.inputverify}
                                />
                                <button
                                    type="button"
                                    className={
                                        otpData.isOtpVerified
                                            ? styles.verified_otp_btn
                                            : styles.verify_otp_btn
                                    }
                                    onClick={handleVerifyOtp}
                                    disabled={otpData.isOtpVerified}
                                >
                                    {otpData.isOtpVerified ? "Verified" : "Verify OTP"}
                                </button>
                                <button
                                    type="button"
                                    className={styles.resend_otp_btn}
                                    onClick={handleResendOtp}
                                >
                                    Resend OTP
                                </button>
                                {otpResendMessage && (
                                    <div className={styles.success_msg}>
                                        {otpResendMessage}
                                    </div>
                                )}
                            </>
                        )}
                        <div className={styles.password_input_container}>
                            <input
                                type={showPassword ? "text" : "password"}
                                placeholder="Password"
                                name="password"
                                onChange={handleChange}
                                value={data.password}
                                required
                                className={styles.input}
                            />
                            <button
                                type="button"
                                className={styles.view_password_btn}
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash}/>
                            </button>
                        </div>
                        {attemptedSignup && !otpData.isOtpVerified && (
                            <div className={styles.error_msg}>
                                Please verify the OTP before signing up.
                            </div>
                        )}
                        <button
                            type="submit"
                            className={styles.green_btn}
                        >
                            Sign Up
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Signup;
