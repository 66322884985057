import React, { useState, useEffect, useRef } from 'react';
import {
  Box, Card, CardContent, CardActions, IconButton, Typography, Avatar, InputBase, Divider, List, ListItem, ListItemAvatar, ListItemText, Button, Paper, Chip
} from '@mui/material';
import { Favorite, Share, Delete, Reply, Comment } from '@mui/icons-material';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Postcommunity = ({ posts, setPosts }) => {
  const [inputText, setInputText] = useState('');
  const [showComments, setShowComments] = useState({});
  const [activeReply, setActiveReply] = useState(null);
  const [replyText, setReplyText] = useState('');
  const [commentLimit, setCommentLimit] = useState(2); // Limit to 2 comments
  const loggedInUser = JSON.parse(localStorage.getItem('user'));
  const [filteredPosts, setFilteredPosts] = useState([]);

  // Create a reference for the comment section
  const commentSectionRef = useRef(null);

  // Initialize filteredPosts when posts are received or updated
  useEffect(() => {
    setFilteredPosts(posts);
  }, [posts]);

  const handleLike = async (postId) => {
    try {
      const response = await axios.put(
        `https://backend.thecameratales.com/api/admin/posts/${postId}/like`,
        {},
        { headers: { 'x-auth-token': localStorage.getItem('token') } }
      );

      const updatedPost = response.data;

      // Update both posts and filteredPosts with the updated post
      setPosts((prevPosts) => prevPosts.map((post) => (post._id === postId ? updatedPost : post)));
      setFilteredPosts((prevFilteredPosts) => prevFilteredPosts.map((post) => (post._id === postId ? updatedPost : post)));
    } catch (error) {
      console.error('Error liking post:', error);
    }
  };

  const handleCommentLike = async (postId, commentId) => {
    try {
      const response = await axios.put(
        `https://backend.thecameratales.com/api/admin/posts/${postId}/comments/${commentId}/like`,
        {},
        { headers: { 'x-auth-token': localStorage.getItem('token') } }
      );

      const updatedPost = response.data;

      // Update both posts and filteredPosts with the updated post
      setPosts((prevPosts) => prevPosts.map((post) => (post._id === updatedPost._id ? updatedPost : post)));
      setFilteredPosts((prevFilteredPosts) => prevFilteredPosts.map((post) => (post._id === updatedPost._id ? updatedPost : post)));
    } catch (error) {
      console.error('Error liking comment:', error);
    }
  };

  const handleShare = (postId) => {
    const shareableUrl = `https://thecameratales.com/sharedpost/${postId}`;
  
    // Check if the Web Share API is supported
    if (navigator.share) {
      navigator.share({
        title: 'Check out this post!',
        url: shareableUrl,
      })
      .then(() => console.log('Post shared successfully!'))
      .catch((error) => console.error('Error sharing post:', error));
    } else {
      // Fallback to copying the URL to the clipboard
      navigator.clipboard.writeText(shareableUrl)
        .then(() => {
          alert('Post link copied to clipboard!');
        })
        .catch((error) => {
          console.error('Error copying link:', error);
        });
    }
  };
  

  const toggleCommentsVisibility = (postId) => {
    setShowComments((prev) => ({
      ...prev,
      [postId]: !prev[postId],
    }));
    // Reset comment limit when toggling comments
    if (!showComments[postId]) setCommentLimit(2);
  };

  const handleComment = async (postId) => {
    if (!loggedInUser) {
      alert('Please log in to comment.');
      return;
    }

    try {
      const response = await axios.post(
        `https://backend.thecameratales.com/api/admin/posts/${postId}/comment`,
        { text: inputText },
        { headers: { 'x-auth-token': localStorage.getItem('token') } }
      );

      const updatedPost = response.data;

      setPosts((prevPosts) => prevPosts.map((post) => (post._id === postId ? updatedPost : post)));
      setFilteredPosts((prevFilteredPosts) => prevFilteredPosts.map((post) => (post._id === postId ? updatedPost : post)));

      setInputText('');
    } catch (error) {
      console.error('Error commenting:', error);
    }
  };

  const handleReply = async (postId, commentId) => {
    try {
      const response = await axios.post(
        `https://backend.thecameratales.com/api/admin/posts/${postId}/comments/${commentId}/reply`,
        { text: replyText },
        { headers: { 'x-auth-token': localStorage.getItem('token') } }
      );

      const updatedPost = response.data;

      // Update posts and filteredPosts
      setPosts((prevPosts) => prevPosts.map((post) => (post._id === postId ? updatedPost : post)));
      setFilteredPosts((prevFilteredPosts) => prevFilteredPosts.map((post) => (post._id === postId ? updatedPost : post)));

      setReplyText(''); // Clear input after reply is added
      setActiveReply(null); // Close the reply input
    } catch (error) {
      console.error('Error replying:', error);
    }
  };

  const loadMoreComments = () => {
    setCommentLimit((prev) => prev + 2); // Load 2 more comments each time
  };

  return (
    <Box sx={{ backgroundColor: '#1a1a1a', padding: '16px', minHeight: '100vh' }}>
      {filteredPosts.map((post) => (
        <Card key={post._id} sx={{ marginBottom: '16px', backgroundColor: '#2c2c2c', color: '#fff' }}>
          <CardContent>
            <Box display="flex" alignItems="center">
              <Avatar alt={post.createdBy.firstName} src={`https://backend.thecameratales.com/uploads/${post.createdBy.profilePicture}`} />
              <Link to={`/users/${post.createdBy._id}`} style={{ textDecoration: 'none' }}>
                <Box ml={2}>
                  <Typography variant="h6" sx={{ color: '#fff' }}>
                    {post.createdBy.firstName} {post.createdBy.lastName}
                  </Typography>
                  <Typography variant="caption" sx={{ color: '#b3b3b3' }}>
                    {new Date(post.date).toLocaleString()}
                  </Typography>
                </Box>
              </Link>
            </Box>
            <Typography sx={{ marginTop: '12px', color: '#fff' }}>{post.caption}</Typography>

            {/* Render Media */}
            {post.mediaType === 'image' && (
              <img
                src={`https://backend.thecameratales.com/uploads/${post.mediaPath}`}
                alt={post.caption}
                style={{ width: '100%', marginTop: '12px', borderRadius: '8px' }}
              />
            )}

            {/* Render Video */}
            {post.mediaType === 'video' && (
              <video
                controls
                controlsList="nodownload"
                style={{
                  width: '100%',
                  marginTop: '12px',
                  borderRadius: '8px',
                }}
              >
                <source
                  src={`https://backend.thecameratales.com/uploads/${post.mediaPath}`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            )}


            {/* Render categories */}
            {post.categories?.length > 0 && (
              <Box sx={{ marginTop: '8px' }}>
                {post.categories.map((category) => (
                  <Chip
                    key={category}
                    label={category}
                    sx={{ marginRight: '8px', backgroundColor: '#3c3c3c', color: '#fff' }}
                  />
                ))}
              </Box>
            )}
          </CardContent>

          <CardActions>
            <IconButton onClick={() => handleLike(post._id)}>
              <Favorite
                sx={{
                  color: post.likes?.includes(loggedInUser?.id) ? '#ff1744' : '#fff' // If user liked, color red, else white
                }}
              />
            </IconButton>
            <Typography sx={{ color: '#fff' }}>{post.likes?.length || 0}</Typography>

            <IconButton onClick={() => toggleCommentsVisibility(post._id)}>
              <Comment sx={{ color: '#fff' }} />
            </IconButton>

            <IconButton onClick={() => handleShare(post._id)}>
              <Share sx={{ color: '#fff' }} />
            </IconButton>

            {loggedInUser?.role === 'admin' && (
              <IconButton>
                <Delete sx={{ color: '#fff' }} />
              </IconButton>
            )}
          </CardActions>
          {/* Comments Section */}
          {showComments[post._id] && (
            <Box sx={{ padding: '16px', backgroundColor: '#1e1e1e', borderRadius: '12px', marginTop: '16px' }} ref={commentSectionRef}>
              <Divider sx={{ backgroundColor: '#4f4f4f', marginBottom: '16px' }} />

              <List sx={{ maxHeight: '250px', overflowY: 'auto', padding: 0 }}>
                {Array.isArray(post.comments) && post.comments.length > 0 ? (
                  // Sort the comments to ensure the logged-in user's comment appears first
                  post.comments
                    .sort((a, b) => (a.user._id === loggedInUser?.id ? -1 : b.user._id === loggedInUser?.id ? 1 : 0))
                    .slice(0, commentLimit) // Limit the number of comments shown
                    .map((comment) => (
                      <ListItem key={comment._id} sx={{ backgroundColor: '#333', borderRadius: '8px', marginBottom: '8px' }}>
                        <ListItemAvatar>
                          <Avatar
                            src={
                              comment.user?.profilePicture
                                ? `https://backend.thecameratales.com/uploads/${comment.user.profilePicture}`
                                : '' // Or provide a default image URL here
                            }
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            comment.user
                              ? `${comment.user.firstName} ${comment.user.lastName}`
                              : 'Unknown User'
                          }
                          secondary={
                            <Typography sx={{ color: '#fff' }}>
                              {comment.text}
                            </Typography>
                          }
                          sx={{ color: '#fff' }} // This ensures the entire ListItemText is styled
                        />
                        <Box>
                          <IconButton onClick={() => handleCommentLike(post._id, comment._id)}>
                            <Favorite sx={{ color: comment.likes?.includes(loggedInUser?.id) ? '#ff1744' : '#fff' }} />
                          </IconButton>
                          <Typography sx={{ color: 'white', textAlign: 'center' }}>
                            {comment.likes?.length || 0}
                          </Typography>
                          {/* <IconButton onClick={() => setActiveReply(comment._id)}>
                  <Reply sx={{ color: '#fff' }} />
                </IconButton> */}
                        </Box>
                      </ListItem>
                    ))
                ) : (
                  <Typography sx={{ color: '#fff' }}>No comments yet.</Typography>
                )}
              </List>

              {/* Load more comments */}
              {post.comments?.length > commentLimit && (
                <Button onClick={loadMoreComments} sx={{ color: '#ff1744', textTransform: 'none' }}>
                  Load More Comments
                </Button>
              )}

              {/* Comment Input */}
              <Box sx={{ marginTop: '16px' }}>
                <InputBase
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  placeholder="Write a comment..."
                  sx={{
                    backgroundColor: '#333',
                    borderRadius: '8px',
                    padding: '8px',
                    width: '100%',
                    color: '#fff',
                  }}
                />
                <Button
                  onClick={() => handleComment(post._id)}
                  sx={{
                    backgroundColor: '#ff1744',
                    color: '#fff',
                    marginTop: '8px',
                    textTransform: 'none',
                  }}
                >
                  Comment
                </Button>
              </Box>
            </Box>
          )}

        </Card>
      ))}
    </Box>
  );
};

export default Postcommunity;
