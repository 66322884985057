import React, { useState } from 'react';
import { TextField, Button, Container, Box, Typography } from '@mui/material';
import axios from 'axios';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${apiBaseURL}/api/contact`, formData);
            alert(response.data.message);
            setFormData({ name: '', email: '', message: '' });
        } catch (error) {
            console.error(error);
            alert('Failed to send message');
        }
    };

    return (
        <Container
            maxWidth="xl"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                padding: '2rem'
            }}
        >
            <Box
                sx={{
                    backgroundColor: '#333',
                    color: '#fff',
                    padding: '3rem',
                    borderRadius: '8px',
                    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.5)',
                    width: '100%',
                    maxWidth: '600px'
                }}
            >
                <Typography variant="h4" align="center" gutterBottom>
                    Contact Us
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        sx={{ mb: 3, backgroundColor: '#555', input: { color: '#fff' } }}
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        sx={{ mb: 3, backgroundColor: '#555', input: { color: '#fff' } }}
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        multiline
                        rows={6}
                        sx={{ mb: 3, backgroundColor: '#555', textarea: { color: '#fff' } }}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        sx={{ backgroundColor: '#000', color: '#fff', padding: '0.75rem' }}
                    >
                        Send Message
                    </Button>
                </form>
            </Box>
        </Container>
    );
};

export default ContactForm;
