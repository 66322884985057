import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import tmdbConfigs from "../api/configs/tmdb.configs";
import mediaApi from "../api/modules/media.api";
import uiConfigs from "../configs/ui.configs";
import HeroSlide from "../components/common/HeroSlide";
import MediaGrid from "../components/common/MediaGrid";
import { setAppState } from "../redux/features/appStateSlice";
import { setGlobalLoading } from "../redux/features/globalLoadingSlice";
import { toast } from "react-toastify";
import usePrevious from "../hooks/usePrevious";

const MediaList = () => {
  const { mediaType } = useParams();

  const [medias, setMedias] = useState([]);
  const [mediaLoading, setMediaLoading] = useState(false);
  const [currCategory, setCurrCategory] = useState(0);
  const [currPage, setCurrPage] = useState(1);

  const prevMediaType = usePrevious(mediaType);
  const dispatch = useDispatch();

  const mediaCategories = useMemo(() => ["popular", "top_rated"], []);
  const category = ["Popular", "Top Rated"]; // Modified category names with capitalization

  useEffect(() => {
    dispatch(setAppState(mediaType));
    window.scrollTo(0, 0);
  }, [mediaType, dispatch]);

  useEffect(() => {
    const getMedias = async () => {
      if (currPage === 1) dispatch(setGlobalLoading(true));
      setMediaLoading(true);

      const { response, err } = await mediaApi.getList({
        mediaType,
        mediaCategory: mediaCategories[currCategory],
        page: currPage
      });

      setMediaLoading(false);
      dispatch(setGlobalLoading(false));

      if (err) toast.error(err.message);
      if (response) {
        if (currPage !== 1) setMedias(m => [...m, ...response.data]);
        else setMedias([...response.data]);
      }
    };

    if (mediaType !== prevMediaType) {
      setCurrCategory(0);
      setCurrPage(1);
    }

    getMedias();
  }, [
    mediaType,
    currCategory,
    prevMediaType,
    currPage,
    mediaCategories,
    dispatch
  ]);

  const onCategoryChange = (categoryIndex) => {
    if (currCategory === categoryIndex) return;
    setMedias([]);
    setCurrPage(1);
    setCurrCategory(categoryIndex);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const onLoadMore = () => setCurrPage(currPage + 1);

  return (
    <>
      <HeroSlide mediaType={mediaType} mediaCategory={mediaCategories[currCategory]} />
      {/* <Box sx={{ ...uiConfigs.style.mainContent }}> */}
      <Stack
        spacing={2}
        direction={{ xs: "column", md: "row" }}
        alignItems="center"
        justifyContent="space-between"
        sx={{ marginBottom: 4, marginLeft: 5, marginTop: 4 }}
      >
         <Typography fontWeight="700" variant="h4" sx={{ color: "white" }}>
          {mediaType.toLowerCase() === "fridaytalkies"
            ? "Friday Talkies"
            : capitalizeFirstLetter(mediaType)}
        </Typography>
      </Stack>
      <Divider sx={{ borderColor: "white" }} /> {/* Horizontal line with white color */}
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        sx={{ marginTop: 2, marginBottom: 4, marginLeft: 5, marginRight: 5 }}
      >
        {category.map((cate, index) => (
          <Button
            key={index}
            size="large"
            variant={currCategory === index ? "contained" : "text"}
            sx={{
              color: currCategory === index ? "primary.contrastText" : "primary.contrastText",
            }}
            onClick={() => onCategoryChange(index)}
          >
            {cate}
          </Button>
        ))}
      </Stack>
      <MediaGrid
        medias={medias}
        mediaType={mediaType}
      />
      <LoadingButton
        sx={{ marginTop: 8 }}
        fullWidth
        color="primary"
        loading={mediaLoading}
        onClick={onLoadMore}
      >
        load more
      </LoadingButton>
      {/* </Box> */}
    </>
  );
};

export default MediaList;
