import React, { useState } from 'react';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SlideshowOutlinedIcon from "@mui/icons-material/SlideshowOutlined";
import LiveTvOutlinedIcon from "@mui/icons-material/LiveTvOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import { Button, Snackbar } from "@mui/material";  // Import Snackbar for notification

const main = [
  {
    display: "home",
    path: "/",
    icon: <HomeOutlinedIcon />,
    state: "home"
  },
  {
    display: "movies",
    path: "/movie",
    icon: <SlideshowOutlinedIcon />,
    state: "movie"
  },
  {
    display: "friday talkies",
    path: "/fridaytalkies", // here
    icon: <SlideshowOutlinedIcon />,
    state: "movie"
  },
  {
    display: "Registration",
    path: "#",  // Disable the link by setting path to "#"
    icon: <LiveTvOutlinedIcon />,
    state: "tv",
    isExternal: true,
    isDisabled: true, // Flag to disable the link
  },
];

const user = [
  {
    display: "favorites",
    path: "/favorites",
    icon: <FavoriteBorderOutlinedIcon />,
    state: "favorite"
  },
  {
    display: "reviews",
    path: "/reviews",
    icon: <RateReviewOutlinedIcon />,
    state: "reviews"
  },
  {
    display: "password update",
    path: "/password-update",
    icon: <LockResetOutlinedIcon />,
    state: "password.update"
  }
];

const Navigation = () => {
  const [open, setOpen] = React.useState(false);

  const handleClick = (item, e) => {
    if (item.isDisabled) {
      e.preventDefault(); // Prevent the default behavior of the link
      setOpen(true); // Show the "Coming Soon" message
    }
  };

  return (
    <div>
      {main.map((item, index) => (
        <div key={index}>
          <a
            href={item.path}
            onClick={(e) => handleClick(item, e)}
            style={{ pointerEvents: item.isDisabled ? "none" : "auto", color: item.isDisabled ? "gray" : "black" }}
          >
            {item.icon} {item.display}
          </a>
        </div>
      ))}
      {open && (
        <Snackbar
          open={open}
          autoHideDuration={3000}
          message="Registration is Coming Soon!"
          onClose={() => setOpen(false)}
        />
      )}

      <div>
        {user.map((item, index) => (
          <div key={index}>
            <a href={item.path}>
              {item.icon} {item.display}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

const menuConfigs = { main, user };

export default menuConfigs;
