import { createSlice } from "@reduxjs/toolkit";

const initialEducationDetailsState = {
  educationInfo: {
    domain: "",
    university: "",
    degree: "",
    startYear: "",
    endYear: "",
  },
};

const educationDetailsSlice = createSlice({
  name: "educationDetails",
  initialState: initialEducationDetailsState,
  reducers: {
    addEducation(state, action) {
      return { ...state, educationInfo: { ...state.educationInfo, ...action.payload } };
    },
  },
});

export const { addEducation } = educationDetailsSlice.actions;
export const educationDetailsReducer = educationDetailsSlice.reducer;
