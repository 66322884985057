import { useRef, useEffect, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Navigation, Pagination]);

const Circle = styled('div')(({ theme }) => ({
  width: "80px",
  height: "80px",
  borderRadius: "50%",
  backgroundColor: "gray",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "5px",
  overflow: "hidden",
  position: "relative",
  [theme.breakpoints.up('sm')]: {
    width: "100px",
    height: "100px",
    margin: "5px"
  },
  "& img": {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));

const Sizzlers = () => {
  const [medias, setMedias] = useState([]);
  const navigate = useNavigate();

  const isMediumScreen = useMediaQuery('(min-width: 801px) and (max-width: 1496px)');
  const isSmallScreen = useMediaQuery('(max-width: 800px)');
  const isSpecificScreenSize = useMediaQuery('(min-width: 1500px) and (max-width: 1600px)');

  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const getMedias = async () => {
      try {
        const response = await axios.get(`${apiBaseURL}/api/video/posts`);
        if (response.status === 200) {
          setMedias(response.data);
        } else {
          console.log("Failed to fetch media.");
        }
      } catch (error) {
        console.error(error);
      }
    };

    getMedias();
  }, []);

  const handleMediaClick = (media, index) => {
    localStorage.setItem('swiperIndex', index); // Store current index in localStorage
    navigate(`/sizzler/${media._id}`, { state: { media } });
  };

  let slidesPerView = 13;

  if (isSmallScreen) {
    slidesPerView = 3;
  } else if (isSpecificScreenSize) {
    slidesPerView = 7;
  } else if (isMediumScreen) {
    slidesPerView = 5;
  }

  return (
    <>
      <div
        style={{
          textAlign: 'left',
          fontSize: isSmallScreen ? '20px' : '35px',
          fontWeight: 'bold',
          backgroundImage: 'linear-gradient(to bottom, #15171a, #15171a, #15171a)',
          color: 'white',
          marginLeft: isSmallScreen ? '3vw' : '1.8vw',
        }}
      >
        <i>Sizzlers...</i>
      </div>
      <div style={{ backgroundImage: `linear-gradient(to bottom, #15171a, #15171a, #15171a)`, backgroundSize: 'contain', paddingTop: '20px', paddingBottom: '20px', border: 'none', marginLeft: "2vw" }}>
        <Swiper
          onSwiper={(swiperInstance) => {
            const savedIndex = localStorage.getItem('swiperIndex');
            if (savedIndex) {
              swiperInstance.slideTo(Number(savedIndex), 0);
            }
          }}
          slidesPerView={slidesPerView}
          navigation
          spaceBetween={1}
        >
          {medias.map((media, index) => (
            <SwiperSlide key={index}>
              <Circle onClick={() => handleMediaClick(media, index)}>
                {media.userId && media.userId.profilePicture ? (
                  <img src={`${apiBaseURL}/uploads/${media.userId.profilePicture}`} alt={media.title} />
                ) : (
                  <span style={{ color: 'white', fontSize: '0.9em' }}>No Image</span>
                )}
              </Circle>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default Sizzlers;
