import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"
import { Avatar } from "@material-ui/core";
import { ArrowBack, MoreVert, ThumbUp, ThumbDown, InsertComment, NearMe } from "@material-ui/icons";
import { useSwipeable } from "react-swipeable";
import axios from "axios"; // Import axios
import styles from "./video.module.css"; // Importing the module.css

function Videos({
  id,
  src,
  channel,
  description,
  initialLikes,
  initialDislikes,
  initialShares,
  initialComments,
  onLike,
  onDislike,
  onShare,
  onComment,
  isPlaying,
  onSubscribe, // New prop for subscribing
  onUnsubscribe, // New prop for unsubscribing
  userFirstName, // New prop
  userLastName, // New prop
  userProfilePicture, // New prop for profile picture
  userFollowers, // New prop for followers count
  userFollowing, // New prop for following count
  targetUserId
}) {
  const [playing, setPlaying] = useState(isPlaying);
  const [subs, setSubs] = useState(false);
  const [likes, setLikes] = useState(initialLikes || 0);
  const [dislikes, setDislikes] = useState(initialDislikes || 0);
  const [shares, setShares] = useState(initialShares || 0);
  const [comments, setComments] = useState(initialComments || []);
  const [newComment, setNewComment] = useState("");
  const [showComments, setShowComments] = useState(false); // New state for toggling comments visibility
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false); // New state for dropdown visibility

  const navigate = useNavigate();

  const videoRef = useRef(null);

  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user ? user.id : null; // Use optional chaining to safely access id

  useEffect(() => {
    if (isPlaying) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    setLikes(initialLikes);
    setDislikes(initialDislikes);
    setShares(initialShares);
    setComments(initialComments);
  }, [initialLikes, initialDislikes, initialShares, initialComments]);



  useEffect(() => {
    console.log("userFollowers:", userFollowers);
    console.log("userId:", userId);
    
    if (Array.isArray(userFollowers)) {
      const isFollower = userFollowers.includes(userId);
      setSubs(isFollower);
    } else {
      console.warn("userFollowers is not an array:", userFollowers);
      setSubs(false); // Default to not subscribed if userFollowers is invalid
    }
  }, [userFollowers, userId]);

  const handleVideoPress = () => {
    setPlaying((prev) => !prev);
    if (playing) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
  };

  const handleSubscribeClick = () => {
    if (subs) {
      onUnsubscribe(); // Call the unsubscribe function
    } else {
      onSubscribe(); // Call the subscribe function
    }
    setSubs((prev) => !prev); // Toggle subscription state
  };



  const handleNewCommentChange = (event) => {
    setNewComment(event.target.value);
  };

  const handleNewCommentSubmit = async () => {
    if (newComment.trim() === "") return;
    try {
      await onComment(newComment);
      setNewComment("");
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  const toggleCommentsVisibility = () => {
    setShowComments(!showComments); // Toggles the visibility of the comments section
  };

   // Toggles the full description view
   const toggleFullDescription = () => {
    setShowFullDescription((prev) => !prev);
  };
  console.log("userId:", userId);
console.log("targetUserId:", targetUserId);

  // Render either the truncated description or the full description
  const renderDescription = () => {
    if (showFullDescription) {
      return description;
    } else {
      const shortDescription = description.split(" ").slice(0, 4).join(" ");
      return shortDescription + (description.split(" ").length > 4 ? "..." : "");
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => console.log("Swiped left"),
    onSwipedRight: () => console.log("Swiped right"),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  // Handle back navigation
  const handleBackClick = () => {
    navigate("/"); // Navigate to the root URL
  };

  // Handle MoreVert click to toggle dropdown
  const handleMoreVertClick = () => {
    setShowDropdown((prev) => !prev); // Toggle dropdown visibility
  };

  const handleNameClick = () => {
    navigate(`/users/${targetUserId}`); // Navigate to user profile using userId
  };

  const handleDeletePost = async () => {
    try {
      // Retrieve the token from local storage using the correct key
      const token = localStorage.getItem("token"); // Use "token" as the key directly
  
      const response = await axios.delete(`https://backend.thecameratales.com/api/video/${id}`, {
        headers: {
          "x-auth-token": token, // Send token in x-auth-token header
        },
      });
      console.log(response.data.message); // Log success message
      // Optionally, navigate away or update the state to remove the post from the UI
      navigate("/"); // Redirect after deletion or handle state update
    } catch (error) {
      console.error("Error deleting video:", error);
      // You can add error handling here, like showing an error message to the user
    }
  };

   // Toggle the dropdown state
   const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  return (
    <div className={styles.video} {...handlers}>
      <video
        id={id}
        className={styles.video__player}
        onClick={handleVideoPress}
        loop
        ref={videoRef}
        src={src}
      />

      <div className={styles.shortsContainer}>
        <div className={styles.shortsVideoTop}>
        <div className={styles.shortsVideoTopIcon} onClick={handleBackClick}>
            <ArrowBack style={{ fontSize: "2rem", cursor: "pointer", color:"white" }} />
          </div>
          <div className={styles.shortsVideoTopIcon}>
          <MoreVert style={{ fontSize: "2rem", cursor: "pointer", color:"white"}} onClick={toggleDropdown}/>
            {showDropdown && ( // Conditionally render the dropdown
              <div className={styles.dropdown}>
              {userId === targetUserId._id && ( // Show delete button if user is the post creator
                <button onClick={handleDeletePost} style={{ color: "red" }}>Delete Post</button>
              )} 
              <p>Coming soon</p>
            </div>
            )}
          </div>
        </div>
        <div className={styles.shortsVideoSideIcons}>
          <div className={styles.shortsVideoSideIcon} onClick={onLike}>
            <ThumbUp style={{ fontSize: "2rem", cursor: "pointer", color: "white" }} />
            <p>{likes}</p>
          </div>
          <div className={styles.shortsVideoSideIcon} onClick={onDislike}>
            <ThumbDown style={{ fontSize: "2rem", cursor: "pointer", color: "white" }} />
            <p>{dislikes}</p>
          </div>
          <div className={styles.shortsVideoSideIcon}>
            <InsertComment style={{ fontSize: "2rem", cursor: "pointer", color: "white" }} />
            <p>{comments.length}</p>
          </div>
          <div className={styles.shortsVideoSideIcon} onClick={onShare}>
            <NearMe style={{ fontSize: "2rem", cursor: "pointer", color: "white" }} />
            <p>{shares}</p>
          </div>
        </div>
        <div className={styles.shortsBottom}>
          <div className={styles.shortsDesc}>
          <p className={styles.description}>
              {renderDescription()}
              {description.split(" ").length > 4 && (
                <span className={styles.readMore} onClick={toggleFullDescription}>
                  {showFullDescription ? " Read less" : " Read more"}
                </span>
              )}
            </p>
          </div>
          <div className={styles.shortDetails}>
            {/* <Avatar src={"https://i.imgur.com/6VBx3io.png"} /> */}
            <Avatar src={`https://backend.thecameratales.com/uploads/${userProfilePicture}`} />
            <p onClick={handleNameClick} style={{ cursor: "pointer" }}>
            {userFirstName} {userLastName}
            </p>
            {userId !== targetUserId && (
             <button onClick={handleSubscribeClick}>{subs ? "Unfollow" : "Follow"}</button>
            )}
          </div>
          {/* Toggle Button for Comments */}
        <button className={styles.toggleCommentsButton} onClick={toggleCommentsVisibility}>
          {showComments ? "Hide Comments" : "Show Comments"}
        </button>
         {showComments && (
          <div className={styles.commentsSection}>
            {comments.map((comment, index) => (
              <div key={index} className={styles.comment}>
                <Avatar src={"https://i.imgur.com/6VBx3io.png"} />
                <p>
                  <strong>{comment.commenterName}</strong>: {comment.commentText}
                </p>
              </div>
            ))}
          </div>
          )}
          <div className={styles.newCommentSection}>
            <input
              type="text"
              value={newComment}
              onChange={handleNewCommentChange}
              placeholder="Add a comment..."
            />
            <button onClick={handleNewCommentSubmit}>Comment</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Videos;
