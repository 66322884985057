  import { useEffect, useState } from "react";
  import { SwiperSlide } from "swiper/react";
  import mediaApi from "../../api/modules/media.api";
  import AutoSwiper from "./AutoSwiper";
  import { toast } from "react-toastify";
  import MediaItem from "./MediaItem";
  import MediaItemTwo from "./MediaItemtwo"
  import { Box, useTheme, useMediaQuery } from "@mui/material";
  import { getAllFavorites } from '../../api/modules/favorite.api';
  import { getViewedMedia } from '../../api/modules/viewed.api';

  const MediaSlide = ({ mediaType, mediaCategory, userId }) => {
    const [medias, setMedias] = useState([]);
    const theme = useTheme();


     // Media query to check if the screen size is less than 600px width and 858px height
  const isSmallScreen = useMediaQuery('(max-width: 600px) and (max-height: 858px)');




    useEffect(() => {
      const getMedias = async () => {
        let response, err;
  
        if (mediaType === "favorites") {
          // Fetch favorite items if mediaType is "favorites"
          ({ response, err } = await getAllFavorites(userId));
        } else if (mediaType === "viewed") {
          // Fetch viewed media if mediaType is "viewed"
          try {
            response = await getViewedMedia(userId);
          } catch (error) {
            err = error;
          }
        } else {
          // Otherwise, fetch media items based on mediaType and mediaCategory
          ({ response, err } = await mediaApi.getList({
            mediaType,
            mediaCategory,
            page: 1
          }));
        }
  
        if (response) {
          console.log("Response received:", response); // Log the full response
          const data = response.data || response;
          console.log("Data extracted:", data); // Log the extracted data
          setMedias(data); // Set the state with the data
        }
  
        if (err) {
          console.error("Error occurred:", err.message); // Log the error message
          toast.error(err.message);
        }
      };
  
      getMedias();
    }, [mediaType, mediaCategory, userId]);
  
  

    return (
      <AutoSwiper>
        {medias
      .filter((media) => media) // Filter out null or undefined items
      .map((media, index) => (
        <SwiperSlide 
          key={index} 
          style={{
            zIndex: 5,
            transition: 'transform 0.3s ease, z-index 0.3s ease',
            [theme.breakpoints.down('md')]: {
              transition: 'none',
            },
            [theme.breakpoints.down('sm')]: { 
              width: '90vw',
            },
          }}
          onMouseEnter={(e) => {
            if (window.innerWidth >= 900) {
              e.currentTarget.style.zIndex = 10;
              e.currentTarget.style.transform = 'scale(1.1)';
            }
          }}
          onMouseLeave={(e) => {
            if (window.innerWidth >= 900) {
              e.currentTarget.style.zIndex = 5;
              e.currentTarget.style.transform = 'scale(1)';
            }
          }}
        >
          {isSmallScreen ? (
              <MediaItemTwo 
                media={media}  
                mediaType={mediaType === "favorites" || mediaType === "viewed" ? media?.type : mediaType} 
              />
            ) : (
              <MediaItem 
                media={media}  
                mediaType={mediaType === "favorites" || mediaType === "viewed" ? media?.type : mediaType} 
              />
            )}
        </SwiperSlide>
      ))}
      </AutoSwiper>
    );
  };

  export default MediaSlide;
