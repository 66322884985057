import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { UserContext } from '../Context/UserContext';

const PaymentForm = () => {
  const { user } = useContext(UserContext);
  const { userId, showId } = useParams();
  const navigate = useNavigate();
  const [ticketPrice, setTicketPrice] = useState(null);

  
  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchMovieDetails = async () => {
      try {
        const { data } = await axios.get(`${apiBaseURL}/api/movies/detail/${showId}`);
        setTicketPrice(data.ticketPrice);
      } catch (error) {
        console.error('Error fetching movie details', error);
      }
    };

    fetchMovieDetails();
  }, [showId]);

  const handlePayment = async () => {
    if (ticketPrice === null) {
      alert('Ticket price not available');
      return;
    }

    try {
      const { data: order } = await axios.post(`${apiBaseURL}/api/payment/create-order`, {
        amount: ticketPrice * 100, // Convert to smallest currency unit (e.g., paise)
        currency: 'INR',
        receipt: `receipt_${userId}_${showId}`,
        plan: 'ticket'
      });

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: order.order.amount,
        currency: order.order.currency,
        name: "OTT Platform",
        description: "Ticket Purchase",
        order_id: order.order.id,
        handler: async function (response) {
          const paymentData = {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
            userId,
            plan: order.plan
          };

          const result = await axios.post(`${apiBaseURL}/api/payment/verify-payment`, paymentData);
          if (result.data.success) {
            navigate(`/fridaytalkies/${showId}/video`);
          } else {
            alert('Payment verification failed');
          }
        },
        prefill: {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          contact: user.contact || '9999999999', // Default contact if not available
        },
        theme: {
          color: "#3399cc"
        }
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error('Payment failed', error);
    }
  };

  return (
    <div>
      <h1>Payment Required</h1>
      {ticketPrice !== null ? (
        <button onClick={handlePayment}>Pay Now</button>
      ) : (
        <p>Loading ticket price...</p>
      )}
    </div>
  );
};

export default PaymentForm;
