import * as React from 'react';
import AnalyticsPage from '../../pages/AnalyticsPage';
import RelatedArtists from './RelatedArtists';

const AdPage = ({ username, imageUrl, likes, caption }) => {
  return (

    <div className="container mt-3">
      <AnalyticsPage/>
      {/* <RelatedArtists/> */}
    </div>
  );
}
 export default AdPage;