import { createSlice } from "@reduxjs/toolkit";

// Personal Info Slice
const personalInfoSlice = createSlice({
  name: "personalInfo",
  initialState: {
    profileImg: "",
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    address: "",
    city: "",
    country: "",
    postalCode: "",
    objective: "",
  },
  reducers: {
    addPersonalInfo(state, action) {
      return { ...state, ...action.payload };
    },
  },
});

export const { addPersonalInfo } = personalInfoSlice.actions;
export const personalInfoReducer = personalInfoSlice.reducer;